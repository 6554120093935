import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Organisation} from "../types/organisation";
import {Zone} from "../types/zone";
import {All_permissions} from "../types/permissions";
import {Capteur} from "../types/capteur";
import {Hub} from "../types/hub";
import {store} from "./store";
import env_config from "../env_config";

const initial_state = {
    lastMiseAJour: 0,
    list: [],
    bandeau_top: {
        texte: "",
        user_close_it: false
    }
};

export type bandeauTopAffichage = {
    texte: string
    user_close_it: boolean
}
export const organisationSlice = createSlice({
    name: "organisation",
    initialState:initial_state as {lastMiseAJour: number, list: Organisation[], bandeau_top:bandeauTopAffichage},
    reducers: {
        addOrganisations: (state, action:PayloadAction<{message:string, organisations:Organisation[]}>) => {
            state.lastMiseAJour = Date.now();
            action.payload.organisations.map(organisation => {
                if (organisation.permissions?.includes("ADMIN"))
                {
                    organisation.permissions = All_permissions.map(p => p.slug);
                    organisation.permissions.push("ADMIN")
                }

                return organisation
            })
            state.list = action.payload.organisations;
            return state;
        },
        editOrganisation: (state, action:PayloadAction<Organisation>) => {
            state.lastMiseAJour = Date.now();
            state.list = state.list.map(orga => {
                if (orga.id === action.payload.id)
                {
                    return action.payload
                }
                return orga;
            })
            return state;
        },
        clearOrganisations: (state, action:PayloadAction<void>) => {
            return initial_state;
        },
        addZonesToOrganisation: (state, action:PayloadAction<{id_orga: number, zones: Zone[], capteurs: Capteur[], hubs:Hub[]}>) => {
            state.list = state.list.map(organisation => {
                if (organisation.id === action.payload.id_orga){
                    let _zones = action.payload.zones;
                    _zones = _zones.map(z => {
                        organisation.zones?.map(zo => {
                            if (z.id === zo.id) z.cellules_minimum = zo.cellules_minimum;
                        })
                        return z;
                    })
                    organisation.zones = _zones;
                    organisation.capteurs = action.payload.capteurs;
                    organisation.hubs = action.payload.hubs;
                }
                organisation.is_fully_loaded = true;
                return organisation
            })

            if (!state.bandeau_top.user_close_it && state.list.filter(o => o.etat === "New" && o.prochainPrelevement && o.balance <= 0).length > 0) {
                state.bandeau_top.texte = env_config.texte_bandeau_affichage_paiement
            }

            return state;
        },
        setBandeauAffichageTexte: (state, action:PayloadAction<string>) => {

            state.bandeau_top.texte = action.payload
            return state

        },
        setBandeauAffichageTextePaiementDemain: (state, action:PayloadAction<void>) => {
            if (!state.bandeau_top.user_close_it && state.list.filter(o => o.etat === "New" && o.prochainPrelevement && o.balance <= 0).length > 0) {
                state.bandeau_top.texte = env_config.texte_bandeau_affichage_paiement
            }
            return state
        },
        setBandeauAffichageEffacer: (state, action:PayloadAction<void>) => {

            state.bandeau_top.texte = ""
            state.bandeau_top.user_close_it = true;
            return state
        },
    }
})

export const {addOrganisations, editOrganisation, clearOrganisations, addZonesToOrganisation,
    setBandeauAffichageTexte,
    setBandeauAffichageTextePaiementDemain,
    setBandeauAffichageEffacer,} = organisationSlice.actions;
