import {Navbar} from "../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Mon_compte.scss'
import {Checkbox, Group, NativeSelect, PasswordInput, Select, Switch, TextInput} from "@mantine/core";
import {Tableau} from "../../components/tableau/Tableau";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {Link, useNavigate} from "react-router-dom";
import {Loading_button} from "../../components/loading_button/loading_button";
import ajax from "../../services/AxiosInterceptor";
import {notifications} from "@mantine/notifications";
import {Bandeau_user} from "../../components/sidebar/bandeau_user/bandeau_user";
import env_config from "../../env_config";
import Sidebar from "../../components/sidebar/Sidebar";
import {setMobile_sidebar_icon} from "../../store/interfaceSlice";
import {IconeCheck} from "../../components/icone/solid_react/check";
import {IconeGear} from "../../components/icone/solid_react/gear";
import {IconeUserSlash} from "../../components/icone/solid_react/user-slash";
import {DateTZ} from "../../services/GestionDates";
import {addUser} from "../../store/userSlice";
import {IconeXmark} from "../../components/icone/solid_react/xmark";
import {IconeStarshipFreighter} from "../../components/icone/solid_react/starship-freighter";
import {IconeLink} from "../../components/icone/solid_react/link";


export function Mon_compte() {

    const user = useSelector<RootState, User>(state => state.user)
    const [nom_affichage, set_nom_affichage] = useState(user.nom_affichage);
    const [nom_famille, set_nom_famille] = useState(user.nom_famille);
    const [email, set_email] = useState(user.email);
    const [telephone, set_telephone] = useState(user.tel);
    const [mot_de_passe, set_mot_de_passe] = useState("");
    const [mot_de_passe_confirme, set_mot_de_passe_confirme] = useState("");
    const [changerMDP, set_changerMDP] = useState(false);
    const [canauxResolutionsAlertes, set_canauxResolutionsAlertes] = useState(user.canaux_resolution_alertes);

    const [fuseauAuto, setFuseauAuto] = useState(user.timezone == null);
    const [fuseau, setFuseau] = useState<string>(user.timezone ? user.timezone+"" : "0");

    const [btn_loading_delete_compte, set_btn_loading_delete_compte] = useState(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("pen-to-square"))

    const navigate = useNavigate();

    function handleValidation(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        let data: {} = {
            "email": email,
            "nom_affichage": nom_affichage,
            "nom_famille": nom_famille,
            "tel": telephone,
            "canaux_resolution_alertes": canauxResolutionsAlertes,
            "timezone": fuseauAuto ? null : Number(fuseau),
        }

        if (changerMDP) {
            if (mot_de_passe !== mot_de_passe_confirme) {
                alert("Les mots de passes ne correspondent pas.")
                set_btn_loading(false)
                return;
            } else {
                data = {
                    ...data,
                    "password": mot_de_passe,
                }
            }
        }

        set_btn_loading(true)

        ajax.put("/users/" + user.id, data).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Enregistré!",
                message: 'Vos coordonnées ont étés mises à jour.',
                icon: <IconeCheck/>,
            });

            ajax.get('/user/getSelf')
                .then(response => {
                    dispatch(addUser(response.data));
                })
                .catch(error => {

                });

            set_btn_loading(false)
        }).catch(error => {
            set_btn_loading(false)
        })
    }

    function handleSupprimerSonCompte() {

        if (window.confirm("Vous êtes sur le point de supprimer votre compte utilisateur. \n" +
            "Vous ne pourrez plus utiliser la plateforme Freshtime et toutes vos informations seront supprimée.\n" +
            "Etes vous sûr de votre choix ?")) {
            set_btn_loading_delete_compte(true)
            ajax.delete("/users/" + user.id).then(response => {
                set_btn_loading_delete_compte(false)
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Compte supprimé",
                    message: 'Votre compte à bien été supprimé. Merci d\'avoir utilisé Freshtime.',
                    icon: <IconeCheck/>,
                });
                navigate("/logout");

            }).catch(error => {
                set_btn_loading_delete_compte(false)
            });
        }

    }

    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content page_mon_compte"}>
                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Mon compte</h1>
                        <p>
                            Voici vos coordonnées connues par la plateforme
                        </p>
                    </div>


                    {/*<div className={"mini-sidebar"}>*/}
                    {/*    <Bandeau_user />*/}
                    {/*</div>*/}

                </div>
                <form action="#" onSubmit={handleValidation}>

                    <label>
                        Prénom *
                        <TextInput
                            placeholder="Elodie"
                            required
                            value={nom_affichage}
                            onChange={e => set_nom_affichage(e.target.value)}
                        />
                    </label>
                    <label>
                        Nom de famille
                        <TextInput
                            placeholder=""
                            value={nom_famille}
                            onChange={e => set_nom_famille(e.target.value)}
                        />
                    </label>
                    <label>
                        Adresse email *
                        <TextInput
                            type="email"
                            placeholder="email@email.fr"
                            required
                            value={email}
                            onChange={e => set_email(e.target.value)}
                        />
                    </label>
                    <label>
                        Numéro de téléphone
                        <TextInput
                            type="tel"
                            placeholder=""
                            value={telephone}
                            onChange={e => set_telephone(e.target.value)}
                        />
                    </label>



                    <div className={"fullWidth"}>
                        <Switch className="checkbox"
                                  label={<p>Fuseau horaire automatique (UTC {new Date().getTimezoneOffset()/60}h)</p>}
                                  checked={fuseauAuto}
                                  onChange={e => setFuseauAuto(e.target.checked)}
                                  style={{margin: "unset"}}
                        />

                        {!fuseauAuto && (
                            <Select
                                value={fuseau}
                                onChange={e => setFuseau(e ?? "0")}
                                data={[
                                    { "value": "-720", "label": "UTC-12:00 - Île Baker, Île Howland" },
                                    { "value": "-660", "label": "UTC-11:00 - Niue, Samoa américaines" },
                                    { "value": "-600", "label": "UTC-10:00 - Heure des îles Aléoutiennes, Tahiti" },
                                    { "value": "-570", "label": "UTC-09:30 - Îles Marquises" },
                                    { "value": "-540", "label": "UTC-09:00 - Heure de l'Alaska, Îles Gambier" },
                                    { "value": "-480", "label": "UTC-08:00 - Heure du Pacifique, Îles Pitcairn" },
                                    { "value": "-420", "label": "UTC-07:00 - Heure des Rocheuses, Chihuahua" },
                                    { "value": "-360", "label": "UTC-06:00 - Heure centrale, Mexico" },
                                    { "value": "-300", "label": "UTC-05:00 - Heure de l'Est, Bogota" },
                                    { "value": "-240", "label": "UTC-04:00 - Heure de l'Atlantique, Caracas" },
                                    { "value": "-210", "label": "UTC-03:30 - Terre-Neuve" },
                                    { "value": "-180", "label": "UTC-03:00 - Argentine, São Paulo" },
                                    { "value": "-120", "label": "UTC-02:00 - Géorgie du Sud et îles Sandwich du Sud" },
                                    { "value": "-60", "label": "UTC-01:00 - Açores, Cap-Vert" },
                                    { "value": "0", "label": "UTC+00:00 - Londres, Lisbonne, Accra" },
                                    { "value": "60", "label": "UTC+01:00 - Heure d'Europe Centrale, Lagos" },
                                    { "value": "120", "label": "UTC+02:00 - Heure d'Europe de l'Est, Le Caire" },
                                    { "value": "180", "label": "UTC+03:00 - Moscou, Nairobi, Riyad" },
                                    { "value": "210", "label": "UTC+03:30 - Téhéran" },
                                    { "value": "240", "label": "UTC+04:00 - Dubaï, Bakou, Samara" },
                                    { "value": "270", "label": "UTC+04:30 - Kaboul" },
                                    { "value": "300", "label": "UTC+05:00 - Islamabad, Karachi, Iekaterinbourg" },
                                    { "value": "330", "label": "UTC+05:30 - Heure de l'Inde, Colombo" },
                                    { "value": "345", "label": "UTC+05:45 - Katmandou" },
                                    { "value": "360", "label": "UTC+06:00 - Dacca, Almaty, Omsk" },
                                    { "value": "390", "label": "UTC+06:30 - Yangon, Îles Cocos" },
                                    { "value": "420", "label": "UTC+07:00 - Bangkok, Jakarta, Krasnoïarsk" },
                                    { "value": "480", "label": "UTC+08:00 - Pékin, Perth, Singapour" },
                                    { "value": "525", "label": "UTC+08:45 - Eucla" },
                                    { "value": "540", "label": "UTC+09:00 - Tokyo, Séoul, Iakoutsk" },
                                    { "value": "570", "label": "UTC+09:30 - Adélaïde, Darwin" },
                                    { "value": "600", "label": "UTC+10:00 - Sydney, Guam, Vladivostok" },
                                    { "value": "630", "label": "UTC+10:30 - Île Lord Howe" },
                                    { "value": "660", "label": "UTC+11:00 - Îles Salomon, Nouvelle-Calédonie" },
                                    { "value": "720", "label": "UTC+12:00 - Auckland, Fidji, Kamtchatka" },
                                    { "value": "765", "label": "UTC+12:45 - Îles Chatham" },
                                    { "value": "780", "label": "UTC+13:00 - Tonga, Îles Phoenix" },
                                    { "value": "840", "label": "UTC+14:00 - Îles de la Ligne" }
                                ]
                                }
                            />
                        )}
                    </div>


                    <Checkbox className="fullWidth checkbox"
                              label={<p>Changer le mot de passe ?</p>}
                              checked={changerMDP}
                              onChange={e => set_changerMDP(e.target.checked)}
                    />

                    <label>
                        Mot de passe *
                        <PasswordInput
                            placeholder="Mot de passe"
                            required
                            value={mot_de_passe}
                            onChange={e => set_mot_de_passe(e.target.value)}
                            disabled={!changerMDP}
                        />
                    </label>
                    <label>
                        Confirmer le mot de passe *
                        <PasswordInput
                            placeholder="Mot de passe"
                            required
                            value={mot_de_passe_confirme}
                            onChange={e => set_mot_de_passe_confirme(e.target.value)}
                            disabled={!changerMDP}
                        />
                    </label>



                    <label className={"fullWidth"}>
                        Sur quels canaux voulez vous recevoir les résolutions ?
                        <p className={"inline-tag"}>Les résolutions sont envoyés quand une anomalie est résolue, par exemple
                            la température d'une cellule est revenue dans la plage acceptable. </p>
                        <Checkbox.Group
                            value={canauxResolutionsAlertes}
                            onChange={value => set_canauxResolutionsAlertes(value)}>
                            <Group mt="xs">
                                {/*<Checkbox value="SMS" label="SMS"/>*/}
                                {/*<Checkbox value="PUSH" label="Push"/>*/}
                                {/*<Checkbox value="APPEL" label="Appel"/>*/}
                                <Checkbox value="EMAIL" label="Email"/>
                            </Group>
                        </Checkbox.Group>
                    </label>

                    <div className={"fullWidth"}>
                        <Loading_button is_loading={btn_loading} onClick={() => {
                        }} className={"big"}>Enregistrer les informations</Loading_button>
                    </div>



                    <div className={"only_mobile_flex"} style={{display: "flex", flexFlow: "column nowrap", gap: "1rem", width: "100%"}}>
                        <h3>Freshtime et vous : </h3>
                        <p><a href={env_config.url_cg_vente} target="_blank">Conditions générales de ventes</a></p>
                        <p><a href={env_config.url_cg_utilisation} target="_blank">Conditions générales d'utilisation</a></p>
                        <p><a href={env_config.url_cookies} target="_blank">Politique des cookies</a></p>
                        <p><a href={env_config.url_politique_confidentialite} target="_blank">Politique de confidentialité</a></p>
                        <p><a href={env_config.url_mentions_legales} target="_blank">Mentions légales</a></p>

                    </div>
                </form>


                <Sidebar>
                    <Bandeau_user/>
                    <div className={"actions"}>
                        <h3><IconeLink/> Freshtime et vous</h3>

                        <div className={"carte_action hide_on_mobile"}>
                            <p className={"titre"}>Liens rapides</p>
                            <a href={env_config.url_cg_vente} target="_blank">Conditions générales de ventes</a>
                            <a href={env_config.url_cg_utilisation} target="_blank">Conditions générales d'utilisation</a>
                            <a href={env_config.url_cookies} target="_blank">Politique des cookies</a>
                            <a href={env_config.url_politique_confidentialite} target="_blank">Politique de confidentialité</a>
                            <a href={env_config.url_mentions_legales} target="_blank">Mentions légales</a>
                        </div>
                        <h3><IconeGear/> Actions pour votre compte</h3>

                        <div className={"carte_action rouge"}>
                            <p className={"titre"}>Supprimer le compte</p>
                            <p className={"infos"}>Attention : cette action est irréversible.</p>
                            <Loading_button is_loading={btn_loading_delete_compte} className={"sec rouge"}
                                            onClick={handleSupprimerSonCompte}><IconeUserSlash/>Supprimer mon
                                compte</Loading_button>
                        </div>
                    </div>
                </Sidebar>


            </div>
        </div>

    );
}
