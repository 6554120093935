import React, {useEffect, useState} from "react";

import {Tableau} from "../../../components/tableau/Tableau";
import ajax from "../../../services/AxiosInterceptor";
import {
    ColumnDef,
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {Accordion, Autocomplete, Modal, NativeSelect, Select, TextInput, Tooltip} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom";
import {Organisation} from "../../../types/organisation";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {
    texte_date_compare_tableau,
    texte_est_inclus,
    texte_simple_compare_tableau
} from "../../../services/GestionTextes";
import {Tableau_helper, typeDonnee} from "../../../components/tableau/Tableau_helper";
import {AxiosResponse} from "axios";
import {Cellule_light_with_organisation} from "../../../types/cellule";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeEye} from "../../../components/icone/solid_react/eye";
import {categorie_de_sonde, Sonde} from "../../../types/sonde";
import {Contact} from "../../../types/contact";
import {Extension} from "../../../types/extension";

export function Sondes() {

    const [categorieDeSonde, setCategorieDeSonde] = useState<categorie_de_sonde[]>(() => []);
    const [donnees, setDonnees] = useState<Sonde[] | null>(() => null);

    const [modale_cellules_recherche, setModale_cellules_recherche] = React.useState<string>("")
    const [Capteurs_modale, setCapteurs_modale] = React.useState<Extension[]|null>(null)
    const [columnVisibility, setColumnVisibility] = React.useState({})

    function removeDuplicates(a:string[]|undefined)
    {
        if (a === undefined) return []
        var seen = {};
        return a.filter(function(item) {
            // @ts-ignore
            return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        });
    }

    const Sonde_Cellule_Tableau_admin:typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {key: "nom", nom: "Nom", desc: "Nom affiché aux clients dans la liste des sondes à brancher."},
        {key: "type_de_donnee", nom: "Type de données", desc: "Type de données généré par cette sonde", type:"select", select_data: [
            {value: "number", label: "Nombre (utilisé pour les valeurs variables comme les températures)"},
            {value: "bool", label: "Bool (valeur binaire, vrai ou faux). Sert pour les contacts secs par exemple."},
        ]},
        {key: "categorie_de_sonde_id", nom: "Categorie de sonde",
            desc: "Les extensions sont limités par leur catégorie. Par exemple, une extension de contact sec ne proposera que des sondes qui ont comme catégorie \"Contact Secs\".",
            wrapper: (texte:string) => (
                <p>{categorieDeSonde.filter(cs => cs.id+"" === texte)[0] ? categorieDeSonde.filter(cs => cs.id+"" === texte)[0].nom : texte}</p>
            ), type:"select", select_data: categorieDeSonde.map(c => {
                return {value: c.id + "", label: categorieDeSonde.filter(cs => cs.id === c.id)[0].nom};
            }),
            },
        {key: "extensions", nom: "Extensions", wrapper: (texte:any) => (
                <Tooltip label={texte.length + " enregistreurs utilisant cette sonde"} onClick={() => setCapteurs_modale(texte)}>
                    <p className={"inline-tag green"}>{texte.length} enregistreurs</p>
                </Tooltip>),
            spanClassname: "no-wrap flex_align_center",
            noedit: true
        },
        {key: "slug_type", type:"autocomplete", nom: "slug_type", desc: "Slug qui indique à la plateforme comment afficher la valeur et quelle icone utilisé.", select_data: removeDuplicates(donnees?.map(d => d.slug_type)).map(d => {
                return {value: d + "", label: d};
            })},
        {key: "affichage_nom_mesure", nom: "Nom d'affichage", desc: "Le nom avec lequel les mesures seront présentés", type:"autocomplete", select_data: removeDuplicates(donnees?.map(d => d.affichage_nom_mesure)).map(d => {
                return {value: d + "", label: d};
            })},
        {key: "affichage_prefix", nom: "Prefix d'affichage", desc: "Le texte à ajouter avant la mesure", cacherDansModaleSi: (row:Sonde) => row.type_de_donnee === "bool"},
        {key: "affichage_suffix", nom: "Suffix d'affichage", desc: "Le texte à ajouter après la mesure. Par exemple \"°C\"", cacherDansModaleSi: (row:Sonde) => row.type_de_donnee === "bool"},
        {key: "affichage_vrai", nom: "Affichage VRAI", desc: "Texte à afficher si l'enregistreur remonte VRAI. Dépend de si la source est NO ou NF.", cacherDansModaleSi: (row:Sonde) => row.type_de_donnee === "number"},
        {key: "affichage_faux", nom: "Affichage FAUX", desc: "Texte à afficher si l'enregistreur remonte FAUX. Dépend de si la source est NO ou NF.", cacherDansModaleSi: (row:Sonde) => row.type_de_donnee === "number"},
        {key: "calcul", nom: "Calcul", desc: "Code PHP qui s'execute à la reception de la mesure, pour permettre de modifier celle-ci en sa version qui sera enregistrée et affichée. Laisser vide pour qu'aucun calcul ne soit appliqué.", wrapper: (texte:string) => (
                <Tooltip label={texte} onClick={() => alert(texte)}>
                    <p className={"inline-tag"}>{texte.length < 23 ? texte : texte.slice(0, 20) + "..."}</p>
                </Tooltip>)},
        {key: "test_deconnexion", nom: "Test deconnexion", desc: "Code PHP exécuté lors d'une condition. La mesure est remplacé par \"#res\", et si la condition est valide alors la mesure sera considéré comme invalide et apparaitra en \"sonde débranché\". Laisser vide pour que la condition ne soit pas testée.", wrapper: (texte:string) => (
                <Tooltip label={texte} onClick={() => alert(texte)}>
                    <p className={"inline-tag"}>{texte.length < 23 ? texte : texte.slice(0, 20) + "..."}</p>
                </Tooltip>)},

        {action_editer: true, cacherDansTableSi: (row) => false},
    ]
    function handleEnregistrerSonde(sonde:Sonde|null, setIsLoading:(isLoading:boolean) => void) {

        if (!sonde)
        {
            alert( "Erreur, sonde est : " + JSON.stringify(sonde))
            return;
        }
        setIsLoading(true);
        let ajax_response = (response:AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'Le sonde est enregistré',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setIsLoading(false);

            if (sonde.id < 0){
                let new_sonde:Sonde = response.data.sonde;
                if (donnees) setDonnees([...donnees, new_sonde])
            }else{
                if (donnees) setDonnees(donnees?.map(donnee => {
                    if (donnee.id === sonde.id){
                        return sonde
                    }
                    return donnee;
                }))
            }
        }
        if (sonde?.id < 0)
        {
            ajax.post("/sondes", sonde).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        } else{
            ajax.put("/sondes/" + sonde.id, sonde).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        }

    }

    function handleSupprimerSonde(sonde:Sonde|null, setIsLoading:(isLoading:boolean) => void) {

        if (!sonde)
        {
            alert( "Erreur, contact est : " + JSON.stringify(sonde))
            return;
        }
        setIsLoading(true);
        ajax.delete("/sondes/" + sonde.id).then((response:AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'orange',
                title: "Validé!",
                message: 'La sonde a été supprimé',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setIsLoading(false);

            if (donnees) setDonnees(donnees.filter(donnee => donnee.id !== sonde.id))
        }).catch(error => {
            setIsLoading(false);
        });

    }

    useEffect(() => {
        ajax.get("/sondes").then(response => setDonnees(response.data.sondes)).catch(e => {
        })
        ajax.get("/categoriesdesondes").then(response => setCategorieDeSonde(response.data.categorieDeSonde)).catch(e => {
        })
    }, [])



    return (
        <div className={"en-tete-pages"}>

            <div className={"titre en-ligne en-tete-page"} style={{margin: "0rem 1rem 0 1rem"}}>
                <h1>Sondes : </h1>
            </div>

            <Tableau_helper
                typeDonnee={Sonde_Cellule_Tableau_admin}
                donnees={donnees}
                handleEnregistrer={handleEnregistrerSonde}
                handleSupprimer={handleSupprimerSonde}
                formatNew={{
                    id : -1,
                    slug_type: "",
                    affichage_nom_mesure: "",
                    type_de_donnee: "number",
                    categorie_de_sonde_id: "1",
                    calcul: "",
                    test_deconnexion: "",
                    affichage_prefix: "",
                    affichage_suffix: "",
                    affichage_vrai: "",
                    affichage_faux: "",
                }}
            />

            <Modal opened={Capteurs_modale !== null} onClose={() => {setCapteurs_modale(null)}} title="Enregistreurs utilisant cette sonde">
                <TextInput
                    placeholder={"Recherche organisation..."}
                    value={modale_cellules_recherche}
                    onChange={e => setModale_cellules_recherche(e.target.value)}
                />
                {(() => {
                    if (Capteurs_modale){
                        let orgas: { nom: string; capteur: string[] }[] = []
                        Capteurs_modale?.map(extension => {
                            let cette_orga = orgas.filter(o => o.nom && o.nom.includes((extension.organisation && extension.organisation?.nom) ?? "Aucune orga"))
                            if (cette_orga.length === 0){
                                orgas.push({
                                    "nom" : ((extension.organisation && extension.organisation?.nom) ?? "Aucune orga"),
                                    "capteur": [extension.capteur.nom]
                                })
                            } else {
                                if (!cette_orga[0].capteur.includes(extension.capteur.nom)){
                                    orgas = orgas.map(o => {
                                        if (o.nom === cette_orga[0].nom)
                                        {
                                            o.capteur.push(extension.capteur.nom)
                                        }
                                        return o;
                                    })
                                }
                            }
                        })
                        return (
                            // eslint-disable-next-line react/jsx-no-undef
                            <Accordion chevronPosition="left" defaultValue="customization">
                                {orgas.filter(o => texte_est_inclus(o.nom, modale_cellules_recherche)).map(orga => (
                                    <Accordion.Item value={orga.nom}>
                                        <Accordion.Control>{orga.nom}</Accordion.Control>
                                        <Accordion.Panel className={"accordion-flex-row"}>
                                            {orga.capteur.map(capteur => {
                                                return (
                                                    <>
                                                        {Capteurs_modale?.filter(
                                                            extension => ((extension.organisation && extension.organisation?.nom) ?? "Aucune orga") === orga.nom
                                                            && extension.capteur.nom === capteur
                                                        ).map(extension => (
                                                            <Link to={"/capteur/" + extension.capteur.uuid} className={"inline-tag"}>{extension.capteur.nom} - {extension.nom}</Link>
                                                        ))}
                                                    </>
                                                )
                                            })}
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )

                    }
                })()}
            </Modal>
        </div>


    );
}
