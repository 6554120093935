import React, {useEffect, useState} from "react";

import './Outils_admin.scss'
import {Autocomplete, NativeSelect, Tabs, TextInput} from "@mantine/core";
import ajax from "../../../services/AxiosInterceptor";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeMagnifyingGlass} from "../../../components/icone/solid_react/magnifying-glass";

export function Ajouter_un_hub() {


    const [uuid, setUuid] = useState("");
    const [ref, setRef] = useState("");
    const [nom, setNom] = useState("");
    const [modele, setModele] = useState("");
    const [versionLogicielle, setVersionLogicielle] = useState("");

    const [cli_output, setCli_output] = useState<string[]|null>(null);

    const [loading_btn, setLoading_btn] = useState(false);


    function handleAjouterHub(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading_btn(true)
        ajax.post("/hubs", {
            "nom": nom,
            "uuid": uuid,
            "ref": ref,
            "modele": modele,
            "version_logicielle": versionLogicielle
        }).then(response => {
            setLoading_btn(false)
            setCli_output(response.data.output_cli)
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'Le hub à été créer.',
                icon: <IconeCheck/>,
            });
        }).catch(error => {
            setLoading_btn(false)
        });
    }


    const [modeles, setModeles] = useState<{[index: string]: string[] }>({});
    useEffect(() => {
        ajax.get("/hub/modeles").then(response => {
            setModeles(response.data.modeles);
        }).catch(e => {
        })
    }, [])

    return (

        <form className={'form_admin'} onSubmit={event => handleAjouterHub(event)}>
            <TextInput
                label={"UUID"}
                description="Référence unique réelle du hub, par laquelle il s'identifie lors des actualisations"
                value={uuid}
                onChange={e => {
                    setUuid(e.target.value)
                    if (ref === "" || ref === uuid) setRef(e.target.value)
                }}
                required
            />
            <TextInput
                label={"Référence Freshtime"}
                description="Référence unique de l'enregistreur que Freshtime veut partager aux client"
                value={ref}
                onChange={e => setRef(e.target.value)}
                required
            />
            <TextInput
                label={"Nom"}
                value={nom}
                onChange={e => setNom(e.target.value)}
                required
            />
            <p className={"inline-tag"}>Note : La référence Freshtime n'est pas utilisé pour l'instant, lors de l'ajout
                d'un enregistreur dans une organisation il faut utiliser l'UUID.</p>

            <Autocomplete
                label={"Modèle"}
                value={modele}
                data={Object.keys(modeles)}
                onChange={e => setModele(e)}
                leftSection={<IconeMagnifyingGlass />}
                required
            />
            <Autocomplete
                label={"Version logicielle"}
                value={versionLogicielle}
                onChange={e => setVersionLogicielle(e)}
                data={modeles[modele] ?? []}
                leftSection={<IconeMagnifyingGlass />}
                required
            />
            <div className={"fullwidth"}>
                <Loading_button is_loading={loading_btn} onClick={() => {}} className={"big"}>Créer le hub (et ses accès au broker et VPN)</Loading_button>
            </div>

            {cli_output && (
                <code className={"fullwidth"}>
                    {cli_output.map(c => <p>{"> "}{c}</p>)}
                </code>

            )}


        </form>

    );
}
