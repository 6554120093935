import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../services/AxiosInterceptor";
import {
    Avatar,
    Button,
    FileButton,
    FileInput,
    Group,
    Modal,
    NumberInput,
    Select,
    Textarea,
    TextInput
} from "@mantine/core";
import {Loading_button} from "../../components/loading_button/loading_button";
import {Capteur} from "../../types/capteur";
import {Extension} from "../../types/extension";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {notifications} from "@mantine/notifications";
import env_config from "../../env_config";
import {getAffichageNom, getAffichageNomShort} from "../../services/UserService";
import {IconeCheck} from "../../components/icone/solid_react/check";
import {IconeSlider} from "../../components/icone/solid_react/slider";
import {IconeEnvelope} from "../../components/icone/solid_react/envelope";
import {IconePhone} from "../../components/icone/solid_react/phone";
import {IconeFileCheck} from "../../components/icone/solid_react/file-check";
import {IconeFileImport} from "../../components/icone/solid_react/file-import";
import {IconeFileExcel} from "../../components/icone/solid_react/file-excel";
import {Link} from "react-router-dom";

export function Modale_etalonner(
    {extension, capteur, setCapteur}
        :{extension:Extension, capteur:Capteur, setCapteur:(capteur:Capteur)=>void}) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);
    const user = useSelector<RootState, User>(state => state.user)

    type Data = {image: string, label: string, value: string, description: string, group: string}[];
    const [commentaire, setCommentaire] = useState<string>("");
    const [coef_c, setCoef_c] = useState<string>("");
    const [coef_b, setCoef_b] = useState<string>("");
    const [coef_a, setCoef_a] = useState<string>("");
    const [fichier, setFichier] = useState<File|null>(null);
    const [regexOK, setRegexOK] = useState<boolean>(false);

    function handleEnregistrer() {
        const formData = new FormData();
        if (!fichier && commentaire === "") {
            alert("Merci d'ajouter un commentaire ou d'uploader un rapport.");
            return
        }
        set_btn_loading(true);
        formData.append("coef_c", coef_c + "");
        formData.append("coef_b", coef_b + "");
        formData.append("coef_a", coef_a + "");
        formData.append("commentaire", commentaire);
        if (fichier) formData.append("fichier", fichier);
        ajax.post("/extensions/" + extension.id + "/etalonnage", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'L\'extension est étalonnée.',
                icon: <IconeCheck/>,
            });
            handlers.close();
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }

    useEffect(() => {
        const regex = /^[+\-*/]\s*\d[\d\s*+/\-.]*$/;

        // Test the input string against the regex
        // setRegexOK(regex.test(coef_c));
        setRegexOK(!isNaN(Number(coef_c)));
    }, [coef_c])

    return (
        <>
            <>
                {extension.sonde && (
                    <button className={"sec"} onClick={handlers.open}><IconeSlider /> Etalonner</button>
                )}
            </>




            <Modal opened={opened} onClose={handlers.close} title="Etalonner l'extension" zIndex={2000}>
                <div className={"form"}>
                    {!user.roles.includes("ROLE_ADMIN") ? (
                        <p className="flex-col" style={{gap: "0.5rem"}}>
                            Pour demander un étalonnage de votre appareil ou fournir un rapport d'étalonnage déjà fait, merci de contacter les équipes Freshtime :

                        <p className={"en-ligne"}><IconeEnvelope />
                            <a href={encodeURI(
                                "mailto:etalonnage@freshtime.fr" +
                                "?subject=Etalonnage sonde " + extension.capteur.uuid +
                                "&body=Demande d'étalonnage de capteur" +
                                "\nOrganisation : " + extension.organisation.nom +
                                "\nEnregistreur : " + extension.capteur.uuid +
                                "\nExtension : " + extension.extension_dispo.nom +
                                "\nSonde : " + extension.sonde?.nom +
                                "\nUtilisateur : " + user.email + " (" + getAffichageNom(user) + ")\n---")
                            }>etalonnage@freshtime.fr
                            </a>
                        </p>
                        <p className={"en-ligne"}><IconePhone /> <a href="tel:+33649831598">+33649831598</a></p>

                        </p>
                    ) : (
                        <>

                            <a className={"en-ligne"} href={"/Etalonnage.xlsx"} target={"_blank"}><IconeFileExcel /> Télécharger le fichier Excel</a>

                            <p>Pour un étalonnage en 1 point, indiquer uniquement C et laisser B et A à 0.</p>
                            <p>Pour un étalonnage linéaire, indiquer C et B et laisser A à 0.</p>
                            <p>Pour un étalonnage polynomial, indiquer C, B et A.</p>

                                <TextInput name={"coef_c"} id={"coef_c"} required
                                           label="Indiquez le coefficient d'étalonnage C : "
                                           placeholder={"0"}
                                           value={coef_c}
                                           onChange={(e) => {setCoef_c(e.target.value.replace(',', "."))}}
                                />
                                <TextInput name={"coef_b"} id={"coef_b"}
                                           label="Indiquez le coefficient d'étalonnage B : "
                                           placeholder={"0"}
                                           value={coef_b}
                                           onChange={(e) => {setCoef_b(e.target.value.replace(',', "."))}}
                                />
                                <TextInput name={"coef_a"} id={"coef_a"}
                                           label="Indiquez le coefficient d'étalonnage A : "
                                           placeholder={"0"}
                                           value={coef_a}
                                           onChange={(e) => {setCoef_a(e.target.value.replace(',', "."))}}
                                />

                                <Textarea name={"commentaire"} id={"commentaire"} required={fichier === null}
                                           label="Commentaire : "
                                            autosize={true}
                                           value={commentaire}
                                           onChange={(e) => setCommentaire(e.target.value)}
                                />
                            <FileButton onChange={setFichier}>
                                {(props) => <button className={"" + (fichier?"vert":"sec")} {...props}>
                                    {fichier ? (
                                        <><IconeFileCheck /> {fichier.name}</>
                                    ) : (<><IconeFileImport /> Upload le rapport d'étalonnage?</>)}
                                </button>}
                            </FileButton>


                            {!regexOK && (
                                <p className="inline-tag red">L'étalonnage ne respecte pas le format attendu</p>
                            )}

                            <Loading_button is_loading={btn_loading}
                                            onClick={handleEnregistrer}
                                            icone={"check"} disabled={!regexOK || (!fichier && commentaire === "")}

                            >{btn_loading ? "" : ""}Appliquer</Loading_button>
                        </>
                        )}

                </div>

            </Modal>
        </>

    )
}