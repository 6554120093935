import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Cellule} from "../../types/cellule";
import {Navbar} from "../../components/navbar/navbar";
import {Bandeau_user} from "../../components/sidebar/bandeau_user/bandeau_user";
import React from "react";
import {Logs_viewer} from "./logs_viewer";

export const Logs_cellule_viewer = () => {
    const { celluleId } = useParams();
    const cellules = useSelector<RootState, Cellule[]>(state => state.cellule)
    let cellule = cellules.filter(c => c.id + "" === celluleId)[0];
    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content content-fullwidth page_logs"}>

                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        {cellule && (
                            <div className={"en-ligne"}>
                                <Link to={"/organisation/" + cellule.organisation.id}><h2>
                                    {cellule.organisation.nom} / {cellule.zone.nom}</h2></Link>
                                <h2> / </h2>
                                <Link to={"/cellule/" + cellule.id}><h2><strong>{cellule.nom}</strong></h2></Link>
                            </div>
                        )}
                        <h1>Historique de la cellule {cellule?.nom}</h1>

                    </div>
                    <div className={"mini-sidebar"}>
                        <Bandeau_user />
                    </div>
                </div>
                <Logs_viewer api_query_url={`/cellules/${celluleId}/logs`} nbe_jour_load={365*2} />
            </div>
        </div>
    );
};