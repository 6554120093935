import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../services/AxiosInterceptor";
import {
    Autocomplete,
    Avatar, CloseButton,
    Group,
    Modal,
    NativeSelect,
    PasswordInput,
    Select,
    Switch,
    Textarea,
    TextInput
} from "@mantine/core";
import {Loading_button} from "../../components/loading_button/loading_button";
import {Hub, hub_config, hub_config_init, wifi_data} from "../../types/hub";
import {IconeMagnifyingGlass} from "../../components/icone/solid_react/magnifying-glass";
import {permissionIsGranted} from "../../services/permissionsService";
import {IconeTrash} from "../../components/icone/solid_react/trash";
import {IconeWifi} from "../../components/icone/solid_react/wifi";
import {IconeCheck} from "../../components/icone/solid_react/check";
import {Tableau_helper, typeDonnee} from "../../components/tableau/Tableau_helper";
import {IconePencil} from "../../components/icone/solid_react/pencil";
import {IconeEthernet} from "../../components/icone/solid_react/ethernet";
import {IconeCross} from "../../components/icone/solid_react/cross";
import {afficherDateTimeSecondes} from "../../services/GestionDates";
import {IconeXmark} from "../../components/icone/solid_react/xmark";
import {IconeArrowRotateLeft} from "../../components/icone/solid_react/arrow-rotate-left";
import {IconeArrowRotateRight} from "../../components/icone/solid_react/arrow-rotate-right";

export function Modale_hub_reseau({
                                      hub,
                                      setEditedHub,
                                      hubs,
                                      setHubs
                                  }: { hub: Hub | null, setEditedHub: (hubs: Hub | null) => void, hubs: Hub[] | null, setHubs: (hubs: Hub[]) => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [wifi_data_full, setWifi_data_full] = useState<any | null>(null);

    const [wifi_edit, setWifi_edit] = useState<hub_config>(hub?.config ?? hub_config_init);

    const [error_msg, setError_msg] = useState("");

    const [hub_maj_tab, setHub_maj_tab] = useState<Hub|null>(hub);

    function handleEnregistrer() {
        if (!hub){
            setError_msg("Erreur, le hub est null.")
            return;
        }

        // if (wifi_edit.enable_wifi && !(/^([0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$/.test(wifi_edit.data.bss) || wifi_edit.data.bss !== "")) {
        //     setError_msg("Le BSSID renseigné n'est pas correct.")
        //     return;
        // }
        if (!wifi_edit.dhcp && !(/^(\d{1,3}\.){3}\d{1,3}$/.test(wifi_edit.static_ip) && wifi_edit.static_ip.split('.').every(num => +num >= 0 && +num <= 255))) {
            setError_msg("L'adresse IP n'est pas correcte.")
            return;
        }
        if (!wifi_edit.dhcp && !(/^(\d{1,3}\.){3}\d{1,3}$/.test(wifi_edit.static_passerelle) && wifi_edit.static_passerelle.split('.').every(num => +num >= 0 && +num <= 255))) {
            setError_msg("L'adresse IP de passerelle n'est pas correcte.")
            return;
        }
        if (!wifi_edit.dhcp && !(/^(\d{1,3}\.){3}\d{1,3}$/.test(wifi_edit.static_masque) && wifi_edit.static_masque.split('.').every(num => +num >= 0 && +num <= 255))) {
            setError_msg("Le masque réseau n'est pas correct.")
            return;
        }

        set_btn_loading(true);

        ajax.put("/hubs/" + hub.uuid, {
            "config": wifi_edit,
        }).then(response => {
            let hubs_maj = hubs?.map(h => {
                if (h.uuid === hub.uuid)
                {
                    let _edit = {...hub}
                    _edit.config = wifi_edit
                    return _edit
                }
                return h;
            })
            if (hubs_maj)
            {
                setHubs(hubs_maj);
                handlers.close();
            }
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }

    function getwifi() {
        set_btn_loading(true);
        ajax.get("/hubs/" + hub?.uuid + "/scan_wifi").then(response => {
            setWifi_data_full(response.data.scan)
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })
    }


    useEffect(() => {
        if (hub) {
            handlers.open()
            setWifi_edit(hub?.config ?? hub_config_init)
        }
        else {
            handlers.close()
            setWifi_data_full(null)
        }
        // setTmp_hub(hub)
    }, [hub])

    useEffect(() => {
        if (hubs && hub)
        {
            setHub_maj_tab(hubs?.find(h => h.uuid === hub?.uuid) ?? null)
        }

    }, [hubs, hub]);


    if (!hub) return <></>

    return (
        <>
            <Modal opened={opened} onClose={() => {
                handlers.close()
                setEditedHub(null);
            }} title={"Paramètres réseau du hub " + hub.uuid}>
                <div className={"form"}>


                    {hub?.vpn_ip && (
                        <p>Adresse IP du hub sur le VPN : {hub.vpn_ip}</p>
                    )}
                    <p>Adresse MAC du hub : {hub.uuid.match(/.{1,2}/g)?.join(':')}</p>



                    <strong>Port RJ45 (Ethernet)</strong>

                    {hub_maj_tab?.heartbeat_etat?.rj45 === 1 && <p className={"inline-tag green"}><IconeEthernet /> Gateway connecté via RJ45 ({afficherDateTimeSecondes(hub_maj_tab?.heartbeat)})</p>}


                    <Switch
                        label={"Utiliser le DHCP"}
                        checked={wifi_edit.dhcp}
                        onChange={e => {
                            let _edit = {...wifi_edit}
                            _edit.dhcp = e.target.checked
                            setWifi_edit(_edit)
                        }}
                    />
                    {!wifi_edit.dhcp && (
                        <>
                            <TextInput
                                label={"Adresse IP statique"}
                                value={wifi_edit.static_ip}
                                onChange={e => {
                                    let _edit = {...wifi_edit}
                                    _edit.static_ip = e.target.value
                                    setWifi_edit(_edit)
                                }}
                            />
                            <TextInput
                                label={"Masque réseau"}
                                value={wifi_edit.static_masque}
                                onChange={e => {
                                    let _edit = {...wifi_edit}
                                    _edit.static_masque = e.target.value
                                    setWifi_edit(_edit)
                                }}
                            />
                            <TextInput
                                label={"Passerelle"}
                                value={wifi_edit.static_passerelle}
                                onChange={e => {
                                    let _edit = {...wifi_edit}
                                    _edit.static_passerelle = e.target.value
                                    setWifi_edit(_edit)
                                }}
                            />
                        </>
                    )}


                    {hub_maj_tab?.heartbeat_etat?.erreur !== "" && <p className={"inline-tag red"}><IconeXmark /> {hub_maj_tab?.heartbeat_etat?.erreur} </p>}


                    <strong>Wifi</strong>
                    <div className={"en-ligne spacebetween"}>
                        <Switch
                            label={"Activer la wifi"}
                            checked={wifi_edit.enable_wifi}
                            onChange={e => {
                                let _edit = {...wifi_edit}
                                _edit.enable_wifi = e.target.checked
                                setWifi_edit(_edit)
                            }}
                        />

                        {wifi_edit.enable_wifi && (wifi_data_full ? (
                            <Modale_view_wifi wifis={wifi_data_full?.result[0].get.map((e: any) => e.value)}
                                              setWifi={(wifi) => {
                                                  if (wifi) {
                                                      let _edit = {...wifi_edit}
                                                      _edit.data = wifi
                                                      setWifi_edit(_edit)
                                                  }
                                              }}
                                              refresh={() => {
                                                    getwifi();
                                                    setWifi_data_full(null)
                                              }
                                              }
                            />
                        ) : (
                            <Loading_button is_loading={btn_loading}
                                            onClick={getwifi}><IconeWifi/> Charger les réseaux wifi
                                disponible</Loading_button>
                        ))}
                    </div>

                    {wifi_edit.enable_wifi && (
                        <>
                            {hub_maj_tab?.heartbeat_etat?.wifi === 1 && <p className={"inline-tag green"}><IconeWifi /> Wifi connectée au réseau {hub_maj_tab?.heartbeat_etat?.ssid}  ({afficherDateTimeSecondes(hub.heartbeat)})</p>}
                            {hub_maj_tab?.heartbeat_etat?.wifi === 0 && <p className={"inline-tag grey"}><IconeWifi /> Wifi non connectée  ({afficherDateTimeSecondes(hub_maj_tab?.heartbeat)})</p>}

                            <TextInput
                                label={"SSID"}
                                value={wifi_edit.data.ssid}
                                onChange={e => {
                                    let _edit = {...wifi_edit}
                                    _edit.data.ssid = e.target.value
                                    setWifi_edit(_edit)
                                }}
                            />

                            <TextInput
                                label={"BSSID"}
                                value={wifi_edit.data.bss}
                                onChange={e => {
                                    let _edit = {...wifi_edit}
                                    _edit.data.bss = e.target.value
                                    setWifi_edit(_edit)
                                }}
                            />
                            <NativeSelect
                                label={"Auth"}
                                data={[
                                    { value: '0', label: "Aucune" },
                                    { value: '1', label: "WEP Open System" },
                                    { value: '2', label: "WEP Shared Key" },
                                    { value: '3', label: "WPA-PSK" },
                                    { value: '4', label: "WPA2-PSK" },
                                    { value: '5', label: "WPA-PSK/WPA2-PSK" },
                                    { value: '6', label: "WPA-Enterprise" },
                                    { value: '7', label: "WPA2-Enterprise" },
                                    { value: '8', label: "WPA-Enterprise/WPA2-Enterprise" },
                                ]}
                                value={wifi_edit.data.auth}
                                onChange={e => {
                                    let _edit = {...wifi_edit}
                                    _edit.data.auth = Number(e.target.value)
                                    setWifi_edit(_edit)
                                }}
                            />
                            {wifi_edit.data.auth > 0 && (
                                <>
                                    {wifi_edit.data.auth > 2 && (
                                        <NativeSelect
                                            label={"Cipher"}
                                            data={[
                                                { value: '0', label: "Auto" },
                                                { value: '1', label: "AES" },
                                                { value: '2', label: "TKIP" },
                                                { value: '3', label: "AES/TKIP"},
                                            ]}
                                            value={wifi_edit.data.cipher}
                                            onChange={e => {
                                                let _edit = {...wifi_edit}
                                                _edit.data.cipher = Number(e.target.value)
                                                setWifi_edit(_edit)
                                            }}
                                        />
                                    )}


                                    <PasswordInput
                                        label={"Mot de passe ( clé )"}
                                        value={wifi_edit.key}
                                        onChange={e => {
                                            let _edit = {...wifi_edit}
                                            _edit.key = e.target.value
                                            setWifi_edit(_edit)
                                        }}
                                    />

                                    {wifi_edit.data.auth > 5 && (
                                        <>
                                            <NativeSelect
                                                label={"XSupplicant Type"}
                                                data={[
                                                    { value: '0', label: "Peap" },
                                                    { value: '1', label: "LEAP" },
                                                    { value: '2', label: "TLS" },
                                                    { value: '3', label: "TTLS"},
                                                ]}
                                                value={wifi_edit.xsupplicant_type}
                                                onChange={e => {
                                                    let _edit = {...wifi_edit}
                                                    _edit.xsupplicant_type = Number(e.target.value)
                                                    setWifi_edit(_edit)
                                                }}
                                            />

                                            <TextInput
                                                label={"User"}
                                                value={wifi_edit.user}
                                                onChange={e => {
                                                    let _edit = {...wifi_edit}
                                                    _edit.user = e.target.value
                                                    setWifi_edit(_edit)
                                                }}
                                            />

                                            <TextInput
                                                label={"Anonymous Identity"}
                                                value={wifi_edit.identity}
                                                onChange={e => {
                                                    let _edit = {...wifi_edit}
                                                    _edit.identity = e.target.value
                                                    setWifi_edit(_edit)
                                                }}
                                            />

                                            <TextInput
                                                label={"Phase2"}
                                                value={wifi_edit.phase2}
                                                onChange={e => {
                                                    let _edit = {...wifi_edit}
                                                    _edit.phase2 = e.target.value
                                                    setWifi_edit(_edit)
                                                }}
                                            />

                                            <Textarea
                                                label={"Public Server Certificate"}
                                                value={wifi_edit.certificate}
                                                onChange={e => {
                                                    let _edit = {...wifi_edit}
                                                    _edit.certificate = e.target.value
                                                    setWifi_edit(_edit)
                                                }}
                                                minRows={3}
                                                autosize
                                            />

                                        </>
                                    )}
                                </>
                            )}


                            <Switch
                                label={"Utiliser le DHCP sur la wifi"}
                                checked={wifi_edit.wifi_dhcp}
                                onChange={e => {
                                    let _edit = {...wifi_edit}
                                    _edit.wifi_dhcp = e.target.checked
                                    setWifi_edit(_edit)
                                }}
                            />

                            {!wifi_edit.wifi_dhcp && (
                                <>
                                    <TextInput
                                        label={"Adresse IP statique"}
                                        value={wifi_edit.wifi_ip}
                                        onChange={e => {
                                            let _edit = {...wifi_edit}
                                            _edit.wifi_ip = e.target.value
                                            setWifi_edit(_edit)
                                        }}
                                    />
                                    <TextInput
                                        label={"Masque réseau"}
                                        value={wifi_edit.wifi_masque}
                                        onChange={e => {
                                            let _edit = {...wifi_edit}
                                            _edit.wifi_masque = e.target.value
                                            setWifi_edit(_edit)
                                        }}
                                    />
                                    <TextInput
                                        label={"Passerelle"}
                                        value={wifi_edit.wifi_passerelle}
                                        onChange={e => {
                                            let _edit = {...wifi_edit}
                                            _edit.wifi_passerelle = e.target.value
                                            setWifi_edit(_edit)
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {error_msg !== "" && <p className={"inline-tag red"}>{error_msg}</p>}


                    <div className={"en-ligne spaceevenly"}>

                        <p className={"inline-tag"}>La configuration peut mettre jusqu'à 5 minute à s'appliquer. Nous vous conseillons de venir voir dans quelques minutes si les statuts de votre appareil sont corrects. Si votre appareil est actuellement hors ligne, il recevra sa configuration quand il se connectera.</p>

                        <Loading_button is_loading={btn_loading}
                                        onClick={handleEnregistrer}><IconeCheck/> Enregistrer et envoyer à l'appareil</Loading_button>
                    </div>


                </div>

            </Modal>
        </>

    )
}


function Modale_view_wifi({wifis, setWifi, refresh}: { wifis: wifi_data[], setWifi: (wifi: wifi_data|undefined) => void, refresh: () => void }) {
    const [opened, handlers] = useDisclosure(false);

    const type_donnees: typeDonnee[] = [
        {key: "ssid", nom: "SSID", noedit: true},
        {key: "signal", nom: "Signal", noedit: true, suffix: "dBm"},
        {key: "bss", nom: "BSSID", noedit: true},
        {
            key: "cipher", nom: "Cipher", noedit: true, edit_display_value: value => {
                if (value === 0) return "Auto"
                if (value === 1) return "AES"
                if (value === 2) return "TKIP"
                if (value === 3) return "AES/TKIP"
                return value
            }
        },
        {
            key: "auth", nom: "Securité", noedit: true, edit_display_value: value => {
                if (value === 0) return "Aucune"
                if (value === 1) return "WEP Open System"
                if (value === 2) return "WEP Shared Key"
                if (value === 3) return "WPA-PSK"
                if (value === 4) return "WPA2-PSK"
                if (value === 5) return "WPA-PSK/WPA2-PSK"
                if (value === 6) return "WPA-Enterprise"
                if (value === 7) return "WPA2-Enterprise"
                if (value === 8) return "WPA-Enterprise/WPA2-Enterprise"
                return value
            }
        },
        {
            key: "bss", nom: "Securité", noedit: true, wrapper: value => {
                return (<button onClick={() => {
                    setWifi(wifis.find(w => w.bss === value))
                    handlers.close();
                }}><IconePencil/></button>)
            }
        },
    ]

    useEffect(() => {
        handlers.open();
    }, []);

    return (
        <>
            <button onClick={handlers.open}>Voir les réseaux wifi</button>
            <Modal opened={opened} onClose={() => {
                handlers.close()
            }} withCloseButton={false}>
                <div className={"modal-en-tete-custom"}>
                    <p className="titre">Réseaux Wifi disponibles</p>

                    <button className={"sec"} onClick={refresh}><IconeArrowRotateRight />Recharger</button>
                    <Group justify="center">
                        <CloseButton aria-label="Fermer la fenetre" onClick={handlers.close}/>
                    </Group>
                </div>
                <div className={"form"}>



                    <Tableau_helper typeDonnee={type_donnees}

                                    donnees={wifis.sort((a, b) => {
                                        if (a.signal > b.signal) return -1
                                        else return 1
                                    })}

                    />

                </div>

            </Modal>
        </>

    )
}