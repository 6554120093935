import React, {useEffect, useState} from "react";

import './Outils_admin.scss'
import {NativeSelect, Tabs, TextInput} from "@mantine/core";
import ajax from "../../../services/AxiosInterceptor";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {Ajouter_un_capteur} from "./Ajouter_un_capteur";
import {Ajouter_une_orga} from "./Ajouter_une_orga";
import {Ajouter_un_hub} from "./Ajouter_un_hub";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeOctagonPlus} from "../../../components/icone/solid_react/octagon-plus";
import {IconeSatelliteDish} from "../../../components/icone/solid_react/satellite-dish";
import {IconeHouseMedical} from "../../../components/icone/solid_react/house-medical";

export function Outils_admin() {


    const [loading_btn, setLoading_btn] = useState(false);

    const [orga_nom, setOrga_nom] = useState("");

    const [activeTab, setActiveTab] = useState<string | null>('add_capteur');


    function handleAjouterOrganisation(event: React.FormEvent<HTMLFormElement>)
    {
        event.preventDefault();
        setLoading_btn(true)
        ajax.post("/organisations", {
            "nom" : orga_nom,
            "coordonnees": [],
            "est_parent": 0
        }).then(response => {
            setLoading_btn(false)
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'L\'organisation à été créer.',
                icon: <IconeCheck/>,
            });
        }).catch(error => {
            setLoading_btn(false)});
    }


    return (
        <div className={"en-tete-pages"}>

            <Tabs defaultValue="add_capteur" className={"Tabs"} value={activeTab} onChange={setActiveTab}>
                <Tabs.List>
                    <Tabs.Tab value="add_capteur" leftSection={<IconeOctagonPlus />}>Ajouter un enregistreur</Tabs.Tab>
                    <Tabs.Tab value="add_hub" leftSection={<IconeSatelliteDish />}>Ajouter un hub</Tabs.Tab>
                    <Tabs.Tab value="add_orga" leftSection={<IconeHouseMedical />}>Créer une organisation</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="add_capteur" pt="xs">
                    {activeTab === "add_capteur" && (
                        <Ajouter_un_capteur />
                    )}
                </Tabs.Panel>

                <Tabs.Panel value="add_hub" pt="xs">
                    {activeTab === "add_hub" && (
                        <Ajouter_un_hub />
                    )}
                </Tabs.Panel>

                <Tabs.Panel value="add_orga" pt="xs">
                    {activeTab === "add_orga" && (
                        <Ajouter_une_orga />
                    )}
                </Tabs.Panel>
            </Tabs>


        </div>

    );
}
