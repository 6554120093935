import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../services/AxiosInterceptor";
import {Avatar, Checkbox, Group, Modal, NumberInput, Select, Switch, TextInput} from "@mantine/core";
import {Loading_button} from "../../components/loading_button/loading_button";
import {Capteur} from "../../types/capteur";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {notifications} from "@mantine/notifications";
import env_config from "../../env_config";
import {IconeCheck} from "../../components/icone/solid_react/check";
import {IconeGear} from "../../components/icone/solid_react/gear";

export function Modale_changer_configurations({
                                                  capteur,
                                                  setCapteur
                                              }: { capteur: Capteur, setCapteur: (capteur: Capteur) => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);
    const user = useSelector<RootState, User>(state => state.user)
    const estAdmin = user.roles.includes("ROLE_ADMIN");

    type Data = { image: string, label: string, value: string, description: string }[];
    const [data, setData] = useState<Data | null>(null);

    const [value_sonde, setValue_sonde] = useState<string | null>(null);

    function handleEnregistrer() {
        if (capteur.configurations === undefined) return;
        let delais = capteur.configurations?.filter(c => c.parametre.slug === "refresh_rate")[0];
        let continuer = false;
        if (parseInt(delais.valeur) > 3600)
        {
            if (window.confirm("Vous avez renseigner un délais au delà d'une heure (3600s). Etes-vous sûr de vouloir valider ?"))
            {
                continuer = true;
            }
        }
        else continuer = true;
        if (continuer){
            set_btn_loading(true);
            ajax.put("/capteurs/" + capteur.uuid + "/configurations", capteur.configurations).then(response => {
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Validé!",
                    message: 'Vos données sont sauvegardés.',
                    icon: <IconeCheck/>,
                });
                handlers.close();
                set_btn_loading(false);
            }).catch(error => {
                set_btn_loading(false);
            })
        }


    }

    if (capteur.configurations === undefined) return (
        <p>Erreur : la configuration de l'enregistreur est undefined</p>
    )

    return (
        <>
            <button className={"sec"} onClick={handlers.open}><IconeGear/> Changer les configurations</button>

            <Modal opened={opened} onClose={handlers.close} title="Changer les configurations">
                <div className={"form"}>


                    {capteur.configurations.map(config => {
                        if (!config.parametre.admin_only || (config.parametre.admin_only && estAdmin)){
                            if (config.parametre.type === "number") return (
                                <NumberInput
                                    label={config.parametre.nom}
                                    description={config.parametre.description + " (Par defaut : "+ config.parametre.valeur_defaut +", minimum : "+ config.parametre.minimum +")"}
                                    value={parseInt(config.valeur)}
                                    min={config.parametre.minimum}
                                    onChange={e => {
                                        let capteur_maj = {...capteur}
                                        capteur_maj.configurations = capteur_maj.configurations?.map(c => {
                                            if (c.parametre.id === config.parametre.id) c.valeur =  e + ""
                                            return c
                                        })
                                        setCapteur(capteur_maj);
                                    }}
                                />
                            )
                            if (config.parametre.type === "string") return (
                                <TextInput
                                    label={config.parametre.nom}
                                    description={config.parametre.description}
                                    value={config.valeur}
                                    onChange={e => {
                                        let capteur_maj = {...capteur}
                                        capteur_maj.configurations = capteur_maj.configurations?.map(c => {
                                            if (c.parametre.id === config.parametre.id) c.valeur = e.target.value
                                            return c
                                        })
                                        setCapteur(capteur_maj);
                                    }}
                                />
                            )
                            if (config.parametre.type === "bool") return (
                                <Switch
                                    label={config.parametre.nom}
                                    description={config.parametre.description}
                                    checked={config.valeur !== "0"}
                                    onChange={e => {
                                        let capteur_maj = {...capteur}
                                        capteur_maj.configurations = capteur_maj.configurations?.map(c => {
                                            if (c.parametre.id === config.parametre.id) c.valeur = e.target.checked ? "1" : "0";
                                            return c
                                        })
                                        setCapteur(capteur_maj);
                                    }}
                                />
                            )

                        }
                        return <></>
                    })}

                    {capteur.configurations.length === 0 ? (
                            <>
                                <p className={"notice grey"}>Aucune configuration n'est disponible pour cet enregistreur.</p>
                                <Loading_button is_loading={btn_loading}
                                                onClick={handlers.close} icone={"check"}>Fermer</Loading_button>
                            </>
                    )
                    : (
                        <>
                            <Loading_button is_loading={btn_loading}
                                            onClick={handleEnregistrer} icone={"check"}>Appliquer</Loading_button>
                        </>
                        )
                    }

                </div>

            </Modal>
        </>

    )
}