import {Navbar} from "../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Bureau_etudes.scss'
import {Tabs} from "@mantine/core";
import {Catalogue_blu} from "./Catalogue_blu/Catalogue_blu";
import {Outils_admin} from "./Outils_admin/Outils_admin";
import {useDispatch} from "react-redux";
import {setMobile_sidebar_icon} from "../../store/interfaceSlice";
import {Logs_viewer_bureau_etude} from "./logs_viewer_bureau_etude";
import {Statut_cellule_admin} from "./Statut_cellule_admin/Statut_cellule_admin";
import {IconeGear} from "../../components/icone/solid_react/gear";
import {IconeFilesMedical} from "../../components/icone/solid_react/files-medical";
import {IconeList} from "../../components/icone/solid_react/list";
import {IconeEuroSign} from "../../components/icone/solid_react/euro-sign";
import {IconeReceipt} from "../../components/icone/solid_react/receipt";
import {IconeSensor} from "../../components/icone/solid_react/sensor";
import {IconeThermometer} from "../../components/icone/solid_react/thermometer";
import {Sondes} from "./Statut_cellule_admin/Sondes";



export function Bureau_etudes() {

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    const [activeTab, setActiveTab] = useState<string | null>('be_admin');

    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content content-fullwidth page_be"}>
                <Tabs className={"Tabs"} value={activeTab} onChange={setActiveTab}>
                    <Tabs.List>
                        <Tabs.Tab value="be_admin" leftSection={<IconeGear />}>Outils d'administration</Tabs.Tab>
                        <Tabs.Tab value="catalogue" leftSection={<IconeFilesMedical/>}>Catalogue Blulog</Tabs.Tab>
                        <Tabs.Tab value="logs" leftSection={<IconeList />}>Logs</Tabs.Tab>
                        <Tabs.Tab value="statuts" leftSection={<IconeReceipt />}>Abonnements cellules</Tabs.Tab>
                        <Tabs.Tab value="sondes" leftSection={<IconeThermometer />}>Sondes</Tabs.Tab>
                        {/*<Tabs.Tab value="page5" icon={<Icone nom={"gear"} />}>Page 5</Tabs.Tab>*/}
                    </Tabs.List>

                    <Tabs.Panel value="be_admin" pt="xs">
                        <Outils_admin />
                    </Tabs.Panel>

                    <Tabs.Panel value="catalogue" pt="xs">
                        {activeTab === "catalogue" && (
                            <Catalogue_blu />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="logs" pt="xs">
                        {activeTab === "logs" && (
                            <Logs_viewer_bureau_etude />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="statuts" pt="xs">
                        {activeTab === "statuts" && (
                            <Statut_cellule_admin />
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="sondes" pt="xs">
                        {activeTab === "sondes" && (
                            <Sondes />
                        )}
                    </Tabs.Panel>

                </Tabs>
            </div>
        </div>

    );
}
