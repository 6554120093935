import React, {useEffect, useState} from "react";

import './Capteurs_liste_admin.scss'
import {Tableau} from "../../../components/tableau/Tableau";
import ajax from "../../../services/AxiosInterceptor";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {NativeSelect, Tooltip} from "@mantine/core";
import {Capteur} from "../../../types/capteur";
import {afficherDateTime, DateTZ} from "../../../services/GestionDates";
import {Icone_signal} from "../../../components/icone/icone_signal/Icone_signal";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {Organisation} from "../../../types/organisation";
import {Link} from "react-router-dom";
import {Modale_editer_capteur} from "./modale_editer_capteur";
import {Hub} from "../../../types/hub";
import {
    texte_date_compare_tableau,
    texte_est_inclus,
    texte_simple_compare_tableau
} from "../../../services/GestionTextes";
import {IconePenToSquare} from "../../../components/icone/solid_react/pen-to-square";


export function Capteurs_liste_admin() {

    const [donnees, setDonnees] = useState<Capteur[] | null>(() => null);

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState({})


    const columnHelper = createColumnHelper<Capteur>()

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const [edited_capteur, setEdited_capteur] = useState<Capteur|null>(null);

    // function handleSupprimerUser(user:Capteur)
    // {
    //     if (window.confirm("Vous êtes sur le point de supprimer l'utilisateur " + user.nom_affichage + "(" + user.email + ")"))
    //     {
    //         setBtn_loading(true)
    //         ajax.delete("/users/" + user.id).then(response => {
    //             setBtn_loading(false)
    //             if (donnees) setDonnees(donnees?.filter(d => d.id !== user.id))
    //         }).catch(error => {
    //             setBtn_loading(false)});
    //     }
    // }

    const columns = [

        columnHelper.accessor(row => row, {
            id: "Organisation",
            header: () => "Organisation",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Organisation : </strong>
                        <Link to={"/organisation/" + info.getValue().organisation_id }>
                            {organisations.filter(o => o.id === info.getValue().organisation_id)[0]?.nom }
                        </Link>

                </span>
                ),
            filterFn: (row: Row<Capteur>,
                       columnId: string,
                       filterValue) => {
                let data:Capteur =  row.getValue(columnId)
                if (data.organisation_id) {
                    return texte_est_inclus(organisations.filter(o => o.id === data.organisation_id)[0]?.nom, filterValue)
                }
                return false
            },
        }),
        columnHelper.accessor(row => row.uuid, {
            id: 'UUID',
            header: () => 'UUID',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>UUID : </strong>
                        <Link to={"/capteur/" + info.getValue()}>{info.getValue()}</Link>
                </span>
                ),
        }),
        columnHelper.accessor(row => row.ref, {
            id: 'Réf',
            header: () => 'Réf',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Réf : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.numero_serie, {
            id: 'numero_serie',
            header: () => 'Numéro de série fabricant',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Numéro de série fabricant: </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.produit.nom, {
            id: 'Produit',
            header: () => 'Produit',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Produit : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.produit.ref, {
            id: 'Produit réf',
            header: () => 'Produit réf',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Produit réf : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.nom, {
            id: 'Nom',
            header: () => 'Nom',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Nom : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.configuration_hash, {
            id: "Config hash",
            header: () => "Config hash",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Config hash : </strong>
                        {info.getValue()}
                </span>
                ),
            enableColumnFilter: false,
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.niveau_batterie, {
            id: "Niveau batterie",
            header: () => "Niveau batterie",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Niveau batterie : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.error_code, {
            id: "Code Erreur",
            header: () => "Code Erreur",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Code Erreur : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.rssi, {
            id: "RSSI",
            header: () => "RSSI",
            cell: info =>
                info.getValue() != null && (
                <span className={"en-ligne paddingZero justify_center"}>
                    <strong className={"th_mobile"}>SNR : </strong>
                        <Icone_signal rssi={info.getValue()}/> {info.getValue()}dBm
                </span>
                ),
        }),
        columnHelper.accessor(row => row.snr, {
            id: "SNR",
            header: () => "SNR",
            cell: info =>
                info.getValue() != null && (
                <span className={"en-ligne paddingZero justify_center"}>
                    <strong className={"th_mobile"}>SNR : </strong>
                        <Icone_signal snr={info.getValue()}/> {info.getValue()}dBm
                </span>
                ),
        }),
        columnHelper.accessor(row => row.creation, {
            id: "Création",
            header: () => 'Création',
            cell: info =>
                <span>
                    <strong className={"th_mobile"}>Création : </strong>
                    {afficherDateTime(new DateTZ(info.getValue()))}
                </span>,
            enableColumnFilter: true,
            sortingFn: (rowA: Row<Capteur>,
                        rowB: Row<Capteur>,
                        columnId: string) => {
                let dateA = new DateTZ(rowA.getValue(columnId))
                let dateB = new DateTZ(rowB.getValue(columnId))
                if (dateA < dateB) return -1
                else if (dateA == dateB) return 0
                else return 1
            },
            filterFn: texte_date_compare_tableau,
        }),
        columnHelper.accessor(row => (row.derniere_mesure ? row.derniere_mesure : null), {
            id: "Dernière mesure",
            header: () => 'Dernière mesure',
            cell: info =>
                <span>
                    <strong className={"th_mobile"}>Dernière mesure : </strong>
                    {afficherDateTime(info.getValue())}
                </span>,
            enableColumnFilter: true,
            sortingFn: (rowA: Row<Capteur>,
                        rowB: Row<Capteur>,
                        columnId: string) => {
                let dateA = new DateTZ(rowA.getValue(columnId))
                let dateB = new DateTZ(rowB.getValue(columnId))
                if (dateA < dateB) return -1
                else if (dateA == dateB) return 0
                else return 1
            },
            filterFn: texte_date_compare_tableau,
        }),
        columnHelper.accessor(row => row.hub, {
            id: "Hub",
            header: () => "Hub",
            cell: info =>
                info.getValue() != null && (
                    <span className={"en-ligne paddingZero justify_center"}>
                    <strong className={"th_mobile"}>Hub : </strong>
                            <Tooltip label={info.getValue().nom} >
                                <Link to={"/hubs?uuid=" + info.getValue().uuid}>{info.getValue().uuid}</Link>
                            </Tooltip>
                </span>
            ),
            sortingFn: (rowA: Row<Capteur>,
                        rowB: Row<Capteur>,
                        columnId: string) => {
                let dateA:Hub = rowA.getValue(columnId)
                let dateB:Hub = rowB.getValue(columnId)
                if (!dateA && !dateB) return 0
                if (!dateA) return -1
                if (!dateB) return 1
                if (dateA.uuid < dateB.uuid) return -1
                else if (dateA.uuid == dateB.uuid) return 0
                else return 1
            },
            filterFn: (row: Row<Capteur>,
                       columnId: string,
                       filterValue) => {
                let hub:Hub = row.getValue(columnId)
                if (hub) {
                    return hub.uuid.toLowerCase().includes(filterValue.toLowerCase()) || hub.nom.toLowerCase().includes(filterValue.toLowerCase())
                }
                return false
            },
        }),
        columnHelper.accessor(row => row.notes, {
            id: 'Notes',
            header: () => 'Notes',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Notes : </strong>
                        {info.getValue().length < 60 ? info.getValue() : info.getValue().slice(0, 57) + "..."}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row, {
            id: 'Edit',
            header: () => '',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <button onClick={() => setEdited_capteur(info.getValue())}><IconePenToSquare/></button>

                </span>
                ),
            enableColumnFilter: false
        }),
    ]


    useEffect(() => {
        ajax.get("/capteurs").then(response => setDonnees(response.data.capteurs)).catch(e => {
        })
    }, [])

    const table = useReactTable<Capteur>({
        data: donnees ? donnees : [],
        columns: columns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })

    return (
        <div className={"en-tete-pages"}>

            <div className={"en-ligne en-tete-page justify_end"}>

                <div className={"titre fullwidth"}>
                    <h1>Tous les enregistreurs de la plateforme</h1>
                </div>

                <div className={"flex-row"}>
                    <div>Colonnes : </div>
                    <button onClick={() => {
                        table.getAllLeafColumns().map(col => {
                            if (['Produit réf', 'Config hash', 'Niveau batterie', 'RSSI', 'SNR', 'Création'].includes(col.id))
                            {
                                col.toggleVisibility(false);
                            }
                        })
                    }} >Afficher minimum</button>
                    <button onClick={() => {
                        table.getAllLeafColumns().map(col => {
                            col.toggleVisibility(true);
                        })
                    }}>Afficher tout</button>
                    |
                </div>





                <div className={"flex-row"}>

                    <div>{table.getRowModel().rows.length} lignes affichés</div>
                    <NativeSelect
                        className={"select_nbe_lignes match_height"}
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                        data={["5", "10", "20", "50", "100"]}
                    />
                </div>

            </div>

            <Tableau table={table} donnees={donnees}/>


            <Modale_editer_capteur capteur={edited_capteur} setEditedCapteur={setEdited_capteur} capteurs={donnees} setCapteurs={setDonnees} />

        </div>

    );
}
