import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Catalogue_blu.scss'
import {Tableau} from "../../../components/tableau/Tableau";
import ajax from "../../../services/AxiosInterceptor";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {afficherDateTime, DateTZ} from "../../../services/GestionDates";
import {Autocomplete, NativeSelect, Select, Tooltip} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom";
import {Organisation} from "../../../types/organisation";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {texte_date_compare_tableau, texte_simple_compare_tableau} from "../../../services/GestionTextes";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeOctagonPlus} from "../../../components/icone/solid_react/octagon-plus";

type Catalogue_blu_capteur = {
    "capteur_ref": string,
    "capteur_nom": string,
    "organisation_nom": string
    "min_temp": number,
    "max_temp": number,
    "hub": string
    "derniere_maj": string
}

export function Catalogue_blu() {

    const [donnees, setDonnees] = useState<Catalogue_blu_capteur[] | null>(() => null);

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState({})


    const [orgaToAdd, setOrgaToAdd] = useState("");
    const [zoneToAdd, setZoneToAdd] = useState("");
    const navigate = useNavigate();

    const columnHelper = createColumnHelper<Catalogue_blu_capteur>()

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const columns = [
        columnHelper.accessor(row => row.capteur_ref, {
            id: 'capteur_ref',
            header: () => 'capteur_ref',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>capteur_ref : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.capteur_nom, {
            id: 'capteur_nom',
            header: () => 'capteur_nom',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>capteur_nom : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.organisation_nom, {
            id: 'organisation_nom',
            header: () => 'organisation_nom',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>organisation_nom : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.min_temp, {
            id: 'min_temp',
            header: () => 'min_temp',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>min_temp : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.max_temp, {
            id: 'max_temp',
            header: () => 'max_temp',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>max_temp : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => row.hub, {
            id: 'hub',
            header: () => 'hub',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>hub : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.derniere_maj, {
            id: "derniere_maj",
            header: () => 'derniere_maj',
            cell: info =>
                <span>
                    <strong className={"th_mobile"}>derniere_maj : </strong>
                    {afficherDateTime(new DateTZ(info.getValue()))}
                </span>,
            enableColumnFilter: true,
            sortingFn: (rowA: Row<Catalogue_blu_capteur>,
                        rowB: Row<Catalogue_blu_capteur>,
                        columnId: string) => {
                let dateA = new DateTZ(rowA.getValue(columnId))
                let dateB = new DateTZ(rowB.getValue(columnId))
                if (dateA < dateB) return -1
                else if (dateA == dateB) return 0
                else return 1
            },
            filterFn: texte_date_compare_tableau,
        }),
        columnHelper.accessor(row => row, {
            id: 'Ajouter l\'enregistreur à l\'organisation selectionnée',
            header: () => '',
            cell: props => props.getValue() ? (
                <Tooltip
                    label={zoneToAdd === "" ? "Ajouter à une organisation" : "Ajouter à l'organisation : " + organisations.filter(o => o.id === parseInt(orgaToAdd))[0].nom}>
                    <button
                        disabled={zoneToAdd === ""}
                        onClick={() => handleAjouterBluCapteur(props.getValue().capteur_nom, props.getValue().capteur_ref)}>
                        <IconeOctagonPlus/></button>
                </Tooltip>
            ) : (<></>),
            enableColumnFilter: false
        }),
    ]

    function handleAjouterBluCapteur(nom: string, ref: string) {
        ajax.post("/catalogue_blu/capteurs", {
            "nom": nom,
            "ref_capteur": ref,
            "zone_id": zoneToAdd
        }).then(response => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'l\'enregistreur à été ajouté à l\'organisation.',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
        }).catch(error => {
        });
    }

    useEffect(() => {
        ajax.get("/catalogue_blu/capteurs").then(response => setDonnees(response.data.catalogue)).catch(e => {
        })
    }, [])

    const table = useReactTable<Catalogue_blu_capteur>({
        data: donnees ? donnees : [],
        columns: columns,
        state: {
            sorting,
            columnVisibility,
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })

    let selectOrga = organisations.filter(o => o.id === parseInt(orgaToAdd))[0];
    let zones = selectOrga ? selectOrga.zones : undefined;
    return (
        <div className={"en-tete-pages"}>

            <div className={"titre en-ligne en-tete-page"} style={{margin: "0rem 1rem 0 1rem"}}>
                <h1>Catalogue blulog : {process.env.PUBLIC_URL}</h1>
            </div>
            <div className={"en-ligne en-tete-page"} style={{margin: "0rem 1rem 0 1rem"}}>
                {/*<div className={"en-ligne en-tete-page"}>*/}
                <div>
                    <p>Pour ajouter un capteur à une organisation, il faut l'indiquer ici :</p>
                    <p className={"inline-tag"}>Cette procédure va ajouter l'enregistreur dans le système, l'ajouter à
                        l'organisation et créer la cellule en la liant au capteur.</p>
                </div>

                <Select
                    label={"Organisation"}
                    placeholder="Choisir une organisation"
                    searchable
                    nothingFoundMessage="Aucune option"
                    data={organisations.map(o => {
                        return {
                            value: o.id + "",
                            label: o.nom,
                        }
                    })}
                    value={orgaToAdd}
                    onChange={e => setOrgaToAdd(e ? e : "")}
                />

                <Select
                    label={"Zone"}
                    placeholder="Choisir la zone"
                    disabled={orgaToAdd === ""}
                    searchable
                    nothingFoundMessage="Aucune option.    (Si les zones n'apparaissent pas, il faut d'abord aller sur la page d'administration de l'organisation pour les charger en mémoire)"
                    data={zones !== undefined ? zones.filter(z => z.nom !== "base.config.appareils").map(z => {
                        return {
                            value: z.id + "",
                            label: z.nom,
                        }
                    }) : []

                    }
                    value={zoneToAdd}
                    onChange={e => setZoneToAdd(e ? e : "")}
                />
                {/*</div>*/}
                <div className={"flex-row"}>

                    <div>{table.getRowModel().rows.length} lignes affichés</div>
                    <NativeSelect
                        className={"select_nbe_lignes match_height"}
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                        data={["5", "10", "20", "50", "100"]}
                    />
                </div>

            </div>

            <Tableau table={table} donnees={donnees}/>

        </div>

    );
}
