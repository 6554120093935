import React, {useEffect, useState} from "react";

import './Outils_admin.scss'
import {NativeSelect, Tabs, TextInput} from "@mantine/core";
import {Produit} from "../../../types/produit";
import ajax from "../../../services/AxiosInterceptor";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {IconeCheck} from "../../../components/icone/solid_react/check";

export function Ajouter_un_capteur() {


    const [uuid, setUuid] = useState("");
    const [ref, setRef] = useState("");
    const [nom, setNom] = useState("");
    const [numero_serie, setNumero_serie] = useState("");
    const [produit_id, setProduit_id] = useState<number | undefined>(undefined);

    const [loading_btn, setLoading_btn] = useState(false);

    const [produits, setProduits] = useState<Produit[] | null>(null);


    useEffect(() => {
        ajax.get("/bureau/admin/produits").then(response => {
            let _produits:Produit[] = response.data.produits;
            setProduits(_produits)
            _produits.map(p => {
                if (produit_id === undefined && !p.ref.includes("blu")) {
                    setProduit_id(p.id)
                }
            })
        }).catch(error => {
        });
    }, [])

    function handleAjouterCapteur(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (produit_id === undefined) {
            alert("Le produit n'est pas indiqué.")
            return;
        }
        setLoading_btn(true)
        ajax.post("/capteurs", {
            "nom": nom,
            "uuid": uuid,
            "ref_capteur": ref,
            "produit_id": produit_id,
            "numero_serie": numero_serie
        }).then(response => {
            setLoading_btn(false)
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'l\'enregistreur à été créer.',
                icon: <IconeCheck/>,
            });
        }).catch(error => {
            setLoading_btn(false)
        });
    }

    return (

        <form className={'form_admin'} onSubmit={event => handleAjouterCapteur(event)}>
            <TextInput
                label={"UUID"}
                description="Référence unique réelle de l'enregistreur, par laquelle il s'identifie lors des actualisations"
                value={uuid}
                onChange={e => {
                    setUuid(e.target.value)
                    if (ref === "" || ref === uuid) setRef(e.target.value)
                }}
                required
            />
            <TextInput
                label={"Référence Freshtime"}
                description="Référence unique de l'enregistreur que Freshtime veut partager aux client"
                value={ref}
                onChange={e => setRef(e.target.value)}
                required
            />
            <TextInput
                label={"Nom"}
                description="Nom pour affichage Admin, sera changé par le client quand il le mettra dans son organisation"
                value={nom}
                onChange={e => setNom(e.target.value)}
                required
            />

            <TextInput
                label={"Numéro de série"}
                description="Numéro de série composé du numéro de lot et numéro unique donné par l'usine"
                value={numero_serie}
                onChange={e => setNumero_serie(e.target.value)}
            />
            <NativeSelect
                data={produits ? produits.map(p => {
                    return {value: p.id + "", label: p.nom}
                }) : []}
                value={produit_id}
                onChange={(event) => {
                    setProduit_id(parseInt(event.currentTarget.value))
                    if (parseInt(event.currentTarget.value) === produits?.filter(p => p.ref.includes("blu"))[0].id) {
                        if (!uuid.includes("blu-")) {
                            setUuid("blu-" + uuid)
                            if (uuid === ref) {
                                setRef("blu-" + ref)
                            }
                            alert("Vous avez selectionné un enregistreur Blulog, le prefix \"blu-\" obligatoire a été rajouter à l'UUID.")
                        }

                    }
                }}
                label="Type de produit"
                description="Va définir quels sont les extensions et les paramètres de l'enregistreur, attention ce n'est pas modifiable par la suite."
                withAsterisk
                defaultValue={produits && produits[0] ? produits[0].id : ""}
            />

            <p className={"inline-tag"}>Note : La référence Freshtime n'est pas utilisé pour l'instant, lors de l'ajout
                d'un hub dans une organisation il faut utiliser l'UUID.</p>
            {produits?.filter(p => p.id === produit_id)[0].ref.includes("blu") && (
                <p className={"notice green"}>Pour ajouter un enregistreur blulog au système il est plus pertinent d'utiliser le catalogue Blulog
                    que d'ajouter un enregistreur par ce formulaire, sauf dans le cas d'un remplacement d'un enregistreur par un autre.</p>
            )}

            <div className={"fullwidth"}>
                <Loading_button is_loading={loading_btn} onClick={() => {}} className={"big"}>Créer l'enregistreur</Loading_button>
            </div>

        </form>

    );
}
