import React, {useEffect, useState} from "react";
import {
    ColumnDef,
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel, getPaginationRowModel, getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Organisation} from "../../types/organisation";
import {texte_custom_compare_tableau, texte_simple_compare_tableau} from "../../services/GestionTextes";
import ajax from "../../services/AxiosInterceptor";
import {Autocomplete, Modal, NativeSelect, Select, Switch, Textarea, TextInput, Tooltip} from "@mantine/core";
import {Tableau} from "./Tableau";
import {Loading_button} from "../loading_button/loading_button";
import {afficherDateTime, DateTZ} from "../../services/GestionDates";
import {DateInput, DateTimePicker} from "@mantine/dates";
import {IconePlus} from "../icone/solid_react/plus";
import {IconePenToSquare} from "../icone/solid_react/pen-to-square";
import {IconeFloppyDisk} from "../icone/solid_react/floppy-disk";
import {IconeTrash} from "../icone/solid_react/trash";

export type typeDonnee = {
    key?: string,
    key_tab?: string, //Au cas ou on utilise deux fois la meme key
    action_editer?: boolean
    nom?: string,
    type?: string
    required?: boolean
    affichageVrai?: string
    affichageFaux?: string
    suffix?: string
    wrapper?: (texte: any) => React.ReactElement;
    spanClassname?: string
    cacherDansTableSi?: (row: any) => boolean
    cacherDansModaleSi?: (row: any) => boolean
    desc?: string
    edit_display_value?: (value: any) => string //Pour modifier la valeur et qu'elle soit prise en compte dans les filter
    noedit?: boolean
    select_data?: { value: string, label: string }[] // Data pour quand le type est Select
    uniquement_new?: boolean // L'option n'est dispo que sur Créer nouveau
    edit_custom_input?: (value: any, onchange: (value: any) => void) => React.ReactElement;
}

export function Tableau_helper(
    {typeDonnee, donnees, handleEnregistrer, handleSupprimer, handleCreer, formatNew}:
        {
            typeDonnee: typeDonnee[],
            donnees: any[] | null,
            handleEnregistrer?: (statut: any, setIsLoading: (isLoading: boolean) => void) => void
            handleSupprimer?: (statut: any, setIsLoading: (isLoading: boolean) => void) => void
            handleCreer?: (statut: any | null) => void
            formatNew?: any
        }) {

    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState({})

    const navigate = useNavigate();

    const columnHelper = createColumnHelper<any>()

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const [modale_edit_objet, setModaleEditObjet] = React.useState<any | null>(null)

    const [est_new, setEst_new] = useState(false);

    let columns: ColumnDef<any, any>[] = [];

    const [btn_loading_modale, set_btn_loading_modale] = useState(false);

    useEffect(() => {
        setModaleEditObjet(null)
    }, [donnees])

    typeDonnee && typeDonnee.map(type => {
        if (type.key) {
            // @ts-ignore
            columns.push(columnHelper.accessor(row => row[type.key], {
                id: type.key_tab ?? type.key,
                header: () => type.nom ?? type.key,
                cell: info => {
                    let valeur = info.getValue();
                    if (type.edit_display_value) valeur = type.edit_display_value(valeur);
                    return valeur != null && (typeof valeur !== "string" || valeur != "") && (
                        <span className={type.spanClassname ?? ""}>
                        <>
                        <strong className={"th_mobile"}>{type.nom ?? type.key} : </strong>
                            {(() => {
                                if (type.cacherDansTableSi !== undefined && type.cacherDansTableSi(info.row.original)) {
                                    return <></>
                                }
                                if (typeof valeur === "string"
                                    || typeof valeur === "number"
                                    || typeof valeur === "boolean") {
                                    let texte_a_afficher = valeur + (type.suffix ?? "");
                                    if (type.type?.includes("boolean") && valeur) {
                                        texte_a_afficher = type.affichageVrai ?? "Oui";
                                    }
                                    if (type.type?.includes("boolean") && !valeur) {
                                        texte_a_afficher = type.affichageFaux ?? "Non";
                                    }
                                    if (type.type?.includes("date")) {
                                        texte_a_afficher = afficherDateTime(new DateTZ(texte_a_afficher));
                                    }
                                    if (type.wrapper) {
                                        return type.wrapper(texte_a_afficher)
                                    } else {
                                        return (
                                            <p>
                                                {texte_a_afficher}
                                            </p>
                                        )
                                    }
                                }

                                if (type.wrapper && valeur != null) {
                                    return type.wrapper(valeur)
                                }

                            })()}
                        </>
                    </span>
                    )
                },
                filterFn: ((type.type === "number") ? 'inNumberRange' : (row, columnId, filterValue) => {
                    if (type.type === "boolean") return texte_custom_compare_tableau(row.getValue(columnId) ? (type.affichageVrai ?? "Oui") : (type.affichageFaux ?? "Non"), filterValue);

                    let value = (type.edit_display_value ? type.edit_display_value(row.getValue(columnId)) : row.getValue(columnId))

                    if (typeof value === "string") return texte_custom_compare_tableau(value, filterValue)


                    return false
                }),
            }))
        }
        else
            if (type.action_editer) {
                columns.push(columnHelper.accessor(row => row, {
                    id: "editer",
                    header: () => "Editer",
                    cell: info => (
                        <>
                            {type.cacherDansTableSi !== undefined && type.cacherDansTableSi(info.row.original) ? (<></>) : (
                                <button onClick={() => {
                                    setModaleEditObjet(info.row.original)
                                    setEst_new(false)
                                }}>
                                    <IconePenToSquare/>
                                </button>
                            )}
                        </>
                    ),
                    enableColumnFilter: false
                }))
            }


        }
    )
        ;


        const table = useReactTable<any>({
            data: donnees ? donnees : [],
            columns: columns,
            state: {
                sorting,
                columnVisibility,
            },
            onSortingChange: setSorting,
            onColumnVisibilityChange: setColumnVisibility,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getFacetedRowModel: getFacetedRowModel(),
            getFacetedUniqueValues: getFacetedUniqueValues(),
            getFacetedMinMaxValues: getFacetedMinMaxValues(),
            enableFilters: true,
            enableColumnFilters: true
        })

        return (
            <div className={"en-tete-pages"}>

                <div className={"en-ligne en-tete-page justify_end"} style={{margin: "0rem 1rem 0 1rem"}}>
                    {/*<div className={"en-ligne en-tete-page"}>*/}


                    <div className={"flex-row"}>

                        {(handleCreer || formatNew) && (
                            <button
                                onClick={() => {
                                    if (formatNew) {
                                        setEst_new(true)
                                        setModaleEditObjet(formatNew)
                                    } else handleCreer && handleCreer(null)
                                }}
                            ><IconePlus/> Créer nouveau</button>
                        )}


                        <div>{table.getRowModel().rows.length} lignes affichés</div>
                        <NativeSelect
                            className={"select_nbe_lignes match_height"}
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                            }}
                            data={["5", "10", "20", "50", "100"]}
                        />
                    </div>

                </div>

                <Tableau table={table} donnees={donnees}/>

                <Modal opened={modale_edit_objet !== null} onClose={() => setModaleEditObjet(null)}
                       title={est_new ? "Créer un nouvel enregistrement" : "Editer"}>
                    <>
                    </>
                    {modale_edit_objet && typeDonnee.filter(t => t.key).map(type => {
                        if (type.noedit) {
                            return <></>
                        }
                        if (type.cacherDansModaleSi !== undefined && type.cacherDansModaleSi(modale_edit_objet)) {
                            return <></>
                        }
                        if (type.edit_custom_input != undefined) {
                            // @ts-ignore
                            return type.edit_custom_input(modale_edit_objet[type.key], e => {
                                let _tmp = {...modale_edit_objet}
                                // @ts-ignore
                                _tmp[type.key] = e
                                setModaleEditObjet(_tmp)
                            })
                        } else if (type.type === "boolean") {
                            return <Switch
                                label={type.nom ?? type.key}
                                description={type.desc ?? null}
                                // @ts-ignore
                                checked={modale_edit_objet[type.key]}
                                onChange={e => {
                                    let _tmp = {...modale_edit_objet}
                                    // @ts-ignore
                                    _tmp[type.key] = e.target.checked
                                    setModaleEditObjet(_tmp)
                                }}
                                style={{marginTop: '0.5rem'}}
                                required={type.required}
                                disabled={type.uniquement_new && !est_new}
                            />
                        } else if (type.type === "number") {
                            return <>input number a dev...</>
                        } else if (type.type === "date") {
                            return <DateTimePicker
                                locale="fr"
                                valueFormat="DD/MM/YYYY HH:mm"
                                label={type.nom ?? type.key}
                                description={type.desc ?? null}
                                // @ts-ignore
                                value={new DateTZ(modale_edit_objet[type.key])}
                                onChange={e => {
                                    let _tmp = {...modale_edit_objet}
                                    // @ts-ignore
                                    _tmp[type.key] = e.toISOString()
                                    setModaleEditObjet(_tmp)
                                }}
                                required={type.required}
                                disabled={type.uniquement_new && !est_new}
                            />
                        } else if (type.type === "textarea") {
                            return <Textarea
                                label={type.nom ?? type.key}
                                description={type.desc ?? null}
                                // @ts-ignore
                                value={modale_edit_objet[type.key]}
                                onChange={e => {
                                    let _tmp = {...modale_edit_objet}
                                    // @ts-ignore
                                    _tmp[type.key] = e.target.value
                                    setModaleEditObjet(_tmp)
                                }}
                                autosize={true}
                                minRows={2}
                                required={type.required}
                                disabled={type.uniquement_new && !est_new}
                            />
                        } else if (type.type === "select") {
                            return <Select
                                label={type.nom ?? type.key}
                                description={type.desc ?? null}
                                defaultValue={type.select_data && type.select_data[0] && type.select_data[0].value}
                                // @ts-ignore
                                value={modale_edit_objet[type.key] + ""}
                                onChange={e => {
                                    let _tmp = {...modale_edit_objet}
                                    // @ts-ignore
                                    _tmp[type.key] = e + ""
                                    setModaleEditObjet(_tmp)
                                }}
                                data={type.select_data ?? [{
                                    value: "error",
                                    label: "Erreur, le data manques dans le TableauHelper"
                                }]}
                                required={type.required}
                                disabled={type.uniquement_new && !est_new}
                            />
                        } else if (type.type === "autocomplete") {
                            return <Autocomplete
                                label={type.nom ?? type.key}
                                description={type.desc ?? null}
                                // defaultValue={type.select_data && type.select_data[0] && type.select_data[0].value}
                                // @ts-ignore
                                value={modale_edit_objet[type.key] + ""}
                                onChange={e => {
                                    let _tmp = {...modale_edit_objet}
                                    // @ts-ignore
                                    _tmp[type.key] = e + ""
                                    setModaleEditObjet(_tmp)
                                }}
                                data={type.select_data ?? [{
                                    value: "error",
                                    label: "Erreur, le data manques dans le TableauHelper"
                                }]}
                                required={type.required}
                                disabled={type.uniquement_new && !est_new}
                            />
                        } else {
                            return <TextInput
                                label={type.nom ?? type.key}
                                description={type.desc ?? null}
                                // @ts-ignore
                                value={modale_edit_objet[type.key]}
                                onChange={e => {
                                    let _tmp = {...modale_edit_objet}
                                    // @ts-ignore
                                    _tmp[type.key] = e.target.value
                                    setModaleEditObjet(_tmp)
                                }}
                                required={type.required}
                                disabled={type.uniquement_new && !est_new}
                            />
                        }
                    })}
                    <div className={"en-ligne spacebetween"} style={{marginTop: "1rem", flexDirection: "row-reverse"}}>
                        <Loading_button is_loading={btn_loading_modale} onClick={() => {
                            modale_edit_objet && handleEnregistrer && handleEnregistrer(modale_edit_objet, set_btn_loading_modale)
                        }}><IconeFloppyDisk/> Enregistrer </Loading_button>
                        {!est_new && handleSupprimer && (
                            <Loading_button is_loading={btn_loading_modale} className={"red"} onClick={() => {
                                modale_edit_objet && handleSupprimer && handleSupprimer(modale_edit_objet, set_btn_loading_modale)
                            }}><IconeTrash/> Supprimer </Loading_button>
                        )}

                    </div>


                </Modal>

            </div>

        );
    }
