import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {IconeLoader} from "../../icone/icone";
import {Checkbox, Modal, Select, Tooltip} from "@mantine/core";
import {Loading_button} from "../../loading_button/loading_button";
import {User} from "../../../types/user";
import ajax from "../../../services/AxiosInterceptor";
import {Organisation} from "../../../types/organisation";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {Cellule} from "../../../types/cellule";
import {addZonesToOrganisation} from "../../../store/organisationSlice";
import "./modale_alertes_users.scss"
import {Alerte} from "../../../types/alerte";
import {Zone} from "../../../types/zone";
import {estDesactivee} from "../../../pages/Orga_admin/Orga_admin";
import {getAffichageNom, getAffichageNomShort} from "../../../services/UserService";
import {IconeBallotCheck} from "../../icone/solid_react/ballot-check";
import {IconeArrowUpToLine} from "../../icone/solid_react/arrow-up-to-line";
import {IconeArrowDownToLine} from "../../icone/solid_react/arrow-down-to-line";
import {setPasApasEtat} from "../../../store/pasApasSlice";

export function Modale_alertes_users_cellules({
                                         organisation_id,
                                         cellule_id,
                                         parent_set_hide, btn_sec, parent_edit_cellule
                                     }: { organisation_id: number, cellule_id?: number, parent_set_hide?: (hide: boolean) => void, parent_edit_cellule?: (cellule:Cellule, shouldClose:boolean) => void , btn_sec?:boolean}) {
    const [opened, handlers] = useDisclosure(false);
    const [garder_fenetre_ouverte, set_garder_fenetre_ouverte] = useState(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const organisation = useSelector<RootState, Organisation>(state => state.organisation.list.filter(o => o.id === organisation_id)[0]);
    const [cellule_selected, setCellule_selected] = useState<Cellule | null>(null);
    const [cellule_selected_no_modif, setCellule_selected_no_modif] = useState<Cellule | null>(null);
    const [orga_all_users, setOrga_all_users] = useState<User[] | null>(null);


    const [hoveredUser, setHoveredUser] = useState<User|null>(null);
    // const alertes_types = ['SMS', 'EMAIL', 'TEL', 'PUSH'];
    const alertes_types = ['EMAIL'];

    let cellules: Cellule[] = []
    organisation.zones?.map(zone => zone.cellules?.map(c => { //.filter(z => z.nom !== "base.config.appareils")
        if (c.conditions.filter(cond => cond.slug !== 8 || c.id === cellule_id || c.zone.nom === "base.config.appareils").length > 0)
        {
            // console.log(c)
            //On vire les cellules qui n'ont que la déco de l'enregistreur sauf si cellule actuelle
            cellules.push(c)
        }

    }))

    useEffect(() => {
        if (!opened) return;
        setIsLoading(true)
        setCellule_selected(null);
        setCellule_selected_no_modif(null);
        ajax.get("/organisations/" + organisation_id + "/utilisateurs").then(response => {
            setOrga_all_users(response.data.utilisateurs);
            ajax.get("/organisations/" + organisation_id + "/lastInfos").then(response => {
                let response_zone:Zone[] = response.data.zones;
                dispatch(addZonesToOrganisation({
                    id_orga: organisation.id,
                    zones: response_zone,
                    capteurs: response.data.capteurs,
                    hubs: response.data.hubs
                }))
                cellules = [];
                response_zone?.map(zone => zone.cellules?.map(c => {
                        cellules.push(c)
                }))

                setCellule_selected(cellules.filter(c => c.id === cellule_id)[0])
                setCellule_selected_no_modif(cellules.filter(c => c.id === cellule_id)[0])
                setIsLoading(false)
            }).catch(error => {
            })
        }).catch(error => {
        })
    }, [opened])

    function handleEnregistrer() {
        if (!cellule_selected) return;
        set_btn_loading(true);
        let alertes:Alerte[] = [];
        cellule_selected.conditions.map(c => c.alertes.map(a => alertes.push(a)))
        ajax.put("/cellules/" + cellule_selected.id + "/alertes", alertes).then(response => {
            setCellule_selected_no_modif(cellule_selected);

            if (!garder_fenetre_ouverte)
            {
                handleClose();
            }

            if (parent_edit_cellule) parent_edit_cellule(cellule_selected, !garder_fenetre_ouverte);

            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }

    function handleOpen() {
        handlers.open();
        if (parent_set_hide) parent_set_hide(true);
        dispatch(setPasApasEtat("modale_alertes_tous_user_opened"))
    }

    function handleClose() {
        handlers.close();
        if (parent_set_hide) parent_set_hide(false);
    }


    if (!organisation.permissions?.includes("Gestion_alertes_toutes")){
        return <></>
    }

    const data = cellules.filter(c => c.conditions.length > 0 || c.id === cellule_id).map(c => {
        return {value: c.id + "",
            label: c.nom + (!c.extension && c.zone.nom!=="base.config.appareils" ? " (désactivée)" : ""),
            group: c.zone.nom === "base.config.appareils" ? "Appareils de l'organisation" : c.zone.nom}
    });

    return (
        <>
            <button className={btn_sec ? "sec" : ""} onClick={handleOpen} disabled={estDesactivee(organisation)} id={"btn_alertes_tous_users"}><IconeBallotCheck/> Gérer les alertes de tous les
                utilisateurs
            </button>


            <Modal className={"modale-width-libre"} opened={opened} onClose={handleClose} title="Gérer les alertes de tous les utilisateurs">


                <Select
                    label="Quelle cellule ?"
                    searchable
                    nothingFoundMessage="Aucune cellule."
                    placeholder={isLoading ? "Chargement..." : "Indiquer une cellule"}
                    data={Array.from(new Set(data.map(d => d.group))).map(group => {
                        return {group: group, items: data.filter(d => d.group === group).map(d => {return {value: d.value, label: d.label}})}
                    })}
                    value={cellule_selected?.id + ""}
                    onChange={e => {
                        if (cellule_selected !== cellule_selected_no_modif && !window.confirm("Vous avez fait des modifications sur les alertes de la cellule " +
                            cellule_selected?.nom + "." +
                            "\nVous devez enregistrer avant de changer de cellule, sinon vos modifications seront perdues. " +
                            "\n\nCliquez sur annuler pour annuler le changement de cellule et enregistrer vos modifications."))
                        {
                            return
                        }
                        setCellule_selected(cellules.filter(c => c.id + "" === e)[0])
                        setCellule_selected_no_modif(cellules.filter(c => c.id + "" === e)[0])
                    }}
                />
                    <div className="tableauContainer tableau_non_responsive tableau_condition">

                        {!cellule_selected
                            ? <p className="en-ligne">
                                {isLoading
                                    ? <div className={"en-ligne"}><IconeLoader /> Chargement...</div>
                                    : (cellules.length > 0 ? "Merci d'indiquer une cellule" : <p className={"notice"}>Aucune cellule de cette organisation n'a d'anomalies configurés.</p> )
                                }</p>
                            : (
                        <table>
                            <thead>
                            <tr>
                                <th className={"inline-tag"}> {cellule_selected?.nom} </th>
                                {orga_all_users?.map(user => (
                                    <th key={user.id} className={hoveredUser?.id === user.id ? "user_hover" : ""}>
                                        <Tooltip label={getAffichageNom(user) + " ("+user.email+")"}>
                                            <p>{getAffichageNomShort(user)}</p>

                                        </Tooltip>

                                    </th>
                                ))}

                            </tr>
                            </thead>
                            <tbody>
                            {cellule_selected?.conditions.map(c => (
                                <tr key={c.id}>
                                    <>
                                        <td>
                                            {c.nom}
                                            <br />
                                            <div className={"nom_condition"}>
                                                {c.slug === 1 && <IconeArrowUpToLine />}
                                                {cellule_selected?.sonde_type?.affichage_prefix}

                                                {c.slug === 2 && cellule_selected?.sonde_type && cellule_selected.sonde_type.affichage_faux }
                                                {c.slug === 3 && cellule_selected?.sonde_type && cellule_selected.sonde_type.affichage_vrai }

                                                {(c.slug === 0 || c.slug === 1) && c.valeur_a_comparer}

                                                {cellule_selected?.sonde_type?.affichage_suffix && ![8,11].includes(c.slug)}
                                                {c.slug === 0 && <IconeArrowDownToLine />}
                                            </div>
                                        </td>
                                        {orga_all_users?.map(user => {
                                            return (
                                                <td key={user.id} className={hoveredUser?.id === user.id ? "user_hover" : ""}
                                                    onMouseEnter={() => setHoveredUser(user)}
                                                    onMouseLeave={() => setHoveredUser(null)}>
                                                    <div className={"ligne_checkbox"}>
                                                        {alertes_types.map(alerte_type => (
                                                            <Checkbox
                                                                key={user.id + c.id + alerte_type}
                                                                size="xs"
                                                                label={alerte_type}
                                                                disabled={
                                                                    (alerte_type === "SMS" && !user.tel)
                                                                    || (alerte_type === "TEL" && !user.tel)
                                                                    || (alerte_type === "PUSH" && !user.tel)
                                                                    || (alerte_type === "EMAIL" && user.statut === "Non validé")
                                                                }
                                                                checked={c.alertes.filter(a =>
                                                                    {
                                                                        console.log(a)
                                                                        return a.utilisateur.id === user.id &&
                                                                            (
                                                                                a.canaux.filter(canal => canal === alerte_type).length > 0
                                                                            )
                                                                    }
                                                                ).length > 0}
                                                                onChange={e => {
                                                                    let _maj_cellule = {...cellule_selected};
                                                                    _maj_cellule.conditions = _maj_cellule?.conditions.map(_condition => {
                                                                        let condition = {..._condition}
                                                                        if (condition.id === c.id) {
                                                                            let find_user = false
                                                                            condition.alertes = condition.alertes.map(_alerte => {
                                                                                let alerte = {..._alerte}
                                                                                if (alerte.utilisateur.id === user.id)
                                                                                {
                                                                                    find_user = true
                                                                                    alerte.canaux = alerte.canaux.filter(canal => canal !== alerte_type)
                                                                                    if (e.target.checked)
                                                                                    {
                                                                                        alerte.canaux.push(alerte_type)
                                                                                    }
                                                                                }
                                                                                return alerte
                                                                            })
                                                                            if (!find_user) condition.alertes.push({
                                                                                id: -1,
                                                                                condition_id: condition.id,
                                                                                utilisateur: user,
                                                                                canaux: [alerte_type],
                                                                                texte_a_envoyer: condition.texte_a_afficher
                                                                            })
                                                                        }
                                                                        return condition
                                                                    })

                                                                    setCellule_selected(_maj_cellule);
                                                                } }
                                                            />
                                                        ))}
                                                    </div>

                                                </td>
                                            )
                                        })}

                                    </>


                                </tr>
                            ))}
                            </tbody>
                        </table>
                        )}
                    </div>



                <div className={"en-ligne spacebetween"}>

                    <Checkbox
                        label="Garder la fenetre ouverte ?"
                        checked={garder_fenetre_ouverte}
                        onChange={e => set_garder_fenetre_ouverte(e.target.checked)}
                    />

                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} disabled={cellule_selected === null || cellule_selected === cellule_selected_no_modif} icone={"check"}>Enregistrer cette cellule</Loading_button>
                </div>

            </Modal>
        </>

    )
}