import {Navbar} from "../../components/navbar/navbar";
import React, {useEffect, useMemo, useState} from "react";

import './Paiements.scss'
import ajax from "../../services/AxiosInterceptor";

import {Link, useLocation, useNavigate} from "react-router-dom";
import {IconeLoader} from "../../components/icone/icone";
import {Bandeau_user} from "../../components/sidebar/bandeau_user/bandeau_user";
import {useDispatch, useSelector} from "react-redux";
import {setMobile_sidebar_icon} from "../../store/interfaceSlice";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {Organisation, Organisation_paiement} from "../../types/organisation";
import {Loading_div} from "../../components/loading_div/loading_div";
import {Autocomplete, Combobox, Input, InputBase, Modal, NativeSelect, Select, Tabs, useCombobox} from "@mantine/core";
import {afficherDate, afficherDateTime} from "../../services/GestionDates";
import {texte_est_inclus, last_mesure_get_couleur} from "../../services/GestionTextes";
import {Modale_recharger_balance} from "./modale_recharger_balance";
import {permissionIsGranted} from "../../services/permissionsService";
import {IconeMagnifyingGlass} from "../../components/icone/solid_react/magnifying-glass";
import {IconeToggleOff} from "../../components/icone/solid_react/toggle-off";
import {IconeList} from "../../components/icone/solid_react/list";
import {IconeListCheck} from "../../components/icone/solid_react/list-check";
import {IconeEye} from "../../components/icone/solid_react/eye";
import {IconeCircleInfo} from "../../components/icone/solid_react/circle-info";


export function Paiements() {

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list.filter(o => o.orga_parent === null))

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const recherche_url = queryParams.get('organisation');

    const [activeTab, setActiveTab] = useState<string | null>(organisations[0] ? "orga-" + organisations[0].id : "pas");
    const [recherche_orga_nom, setRecherche_orga_nom] = useState('');
    const [orga_selectionnee, setOrga_selectionnee] = useState<string>((organisations.filter(o => o.id + "" === recherche_url)[0] && organisations.filter(o => o.id + "" === recherche_url)[0].id + "") ?? organisations[0].id + "")

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    useEffect(() => {
        if (organisations.filter(o => texte_est_inclus(o.id + "", orga_selectionnee))[0]){

            setActiveTab("orga-" + organisations.filter(o => texte_est_inclus(o.id + "", orga_selectionnee))[0].id)
        }
    }, [orga_selectionnee]);

    function checkSiDoitAfficherMarqueurPaiementRouge(o:Organisation)
    {
        return (o.etat === "Desac_paiement")

    }

    function checkSiDoitAfficherMarqueurPaiementJaune(o:Organisation)
    {
        return (o.etat === "New" && o.prochainPrelevement && o.balance <= 0)
            || (o.etat === "Active" && o.prochainPrelevement && o.balance <= 0)

    }

    return (
        <div className={"app"}>
            <Navbar/>

            <div className={"content content-fullwidth en-tete-pages page_paiement"}>


                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Paiements</h1>
                        <p>
                            Voici les informations concernant les prélèvements et apport concernant vos organisations
                        </p>
                    </div>

                    <div className={"mini-sidebar"}>
                        <Bandeau_user/>
                    </div>


                </div>
                {organisations === null ? (
                    <div className={"en-ligne en-tete-page"}>

                        <div className={"en-ligne"}><IconeLoader /> Chargement...</div>


                    </div>
                ) : (
                    <div style={{margin: "1rem"}}>
                        {/*ORGA > balance > orgas enfants > liste cellules avec prix > tarifs totaux*/}
                        <div className={"recherche"}>
                            {/*<IconeMagnifyingGlass/>*/}
                            {/*<Select*/}
                            {/*       placeholder={"Recherche d'une organisation..."}*/}
                            {/*       className={"big_input"}*/}
                            {/*       value={orga_selectionnee}*/}
                            {/*       onChange={event => setOrga_selectionnee(event ?? "")}*/}
                            {/*       data={[{group: "Organisations disponible", items: organisations.map(o => {*/}
                            {/*                  return {value: o.id + "", label: "🛈 " + o.nom}*/}
                            {/*              })}]}*/}
                            {/*       leftSection={<IconeMagnifyingGlass/>}*/}
                            {/*       searchable*/}
                            {/*/>*/}
                            <Combobox
                                store={combobox}
                                onOptionSubmit={(val) => {
                                    setOrga_selectionnee(val ?? "")
                                    combobox.closeDropdown();
                                }}
                            >
                                <Combobox.Target>
                                    <Input
                                        component="button"
                                        type="button"
                                        pointer
                                        rightSection={<Combobox.Chevron />}
                                        rightSectionPointerEvents="none"
                                        leftSection={<IconeMagnifyingGlass/>}
                                        className={"paiement_input_recherche"}
                                        onClick={() => combobox.toggleDropdown()}
                                    >
                                        {organisations.find(o => o.id + "" === orga_selectionnee)?.nom
                                            || <Input.Placeholder>Recherche d'une organisation...</Input.Placeholder>}
                                    </Input>
                                </Combobox.Target>

                                <Combobox.Dropdown>
                                    <Combobox.Search
                                        value={recherche_orga_nom}
                                        onChange={(event) => setRecherche_orga_nom(event.currentTarget.value)}
                                        placeholder="Recherche d'une organisation..."
                                    />
                                    <Combobox.Options>
                                        {organisations.filter((o) => texte_est_inclus(o.nom, recherche_orga_nom)).length === 0 && <Combobox.Empty>Aucune organisation trouvée.</Combobox.Empty>}
                                        {organisations.filter((o) => texte_est_inclus(o.nom, recherche_orga_nom)).map((o) => (
                                        <Combobox.Option value={o.id + ""} key={o.id}
                                                         className={"en-ligne"}
                                                         style={checkSiDoitAfficherMarqueurPaiementRouge(o) ? {color: "var(--error-color)"} : checkSiDoitAfficherMarqueurPaiementJaune(o) ? {color: "var(--warning-color)"} : {}}>
                                            {(checkSiDoitAfficherMarqueurPaiementRouge(o) || checkSiDoitAfficherMarqueurPaiementJaune(o)) && (
                                                <IconeCircleInfo />
                                            )}
                                            {o.nom}
                                        </Combobox.Option>
                                    ))}</Combobox.Options>
                                </Combobox.Dropdown>
                            </Combobox>
                        </div>

                        {organisations.length <= 0 ? "Vous ne pouvez gérer aucune des organisations auquelles vous avez accès."
                            : (

                                <>
                                    {organisations.map(organisation => (
                                        <>

                                            {!permissionIsGranted(organisation.id, "Administrer_organisation") &&  (
                                                <>
                                                        <br />
                                                        <br />
                                                        <p className={"notice"}>Vous n'avez pas la permission de consulter ces données. </p>

                                                </>
                                            )}
                                            {permissionIsGranted(organisation.id, "Administrer_organisation") && activeTab && activeTab === "orga-" + organisation.id && (
                                                <Paiement_orga organisation={organisation}
                                                               key={"orga-" + organisation.id}/>
                                            )}
                                        </>
                                    ))}
                                </>
                            )}


                    </div>
                )}


            </div>
        </div>

    );
}

function Paiement_orga({organisation}: { organisation: Organisation }) {
    const navigate = useNavigate();

    const user = useSelector<RootState, User>(state => state.user)

    const [organisation_full, setOrganisationFull] = useState<Organisation_paiement | null>(null)
    const [modale_paiements, setModalePaiements] = useState<Organisation_paiement | null>(null)
    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    useEffect(() => {
        queryOrga();
    }, [organisation.id])

    function queryOrga()
    {
        ajax.get("/organisations/" + organisation.id + "/paiement").then(response => {
            setOrganisationFull(response.data.organisation);
        }).catch(e => {
        })
    }


    if (organisation_full === null) {
        return (
                <Loading_div/>
        )
    }
    
    let dernier_paiement = null;
    if (organisation_full?.paiements) dernier_paiement = organisation_full?.paiements[0]


    return (
        <>
            <div className={"accueil_organisation"}>
                <div className={"en-tete-orga"}>
                    <h2>
                        {organisation_full.organisation.desactivation_texte !== null ? organisation_full.organisation.nom : ""}
                        {organisation_full.organisation.desactivation_texte !== null
                            && (!user.roles.includes("ROLE_ADMIN") && !user.roles.includes("ROLE_PRESTATAIRE")) && (
                                <span className={"inline-tag"}
                                      style={{color: "var(--warning-color)"}}>
                                <IconeToggleOff/> Organisation désactivée
                            </span>
                            )}
                    </h2>
                </div>


                {organisation_full.enfants.length > 0 && (
                    <div className={"en-ligne"}>
                        Organisations enfants : {organisation_full.enfants.map(enfant => <i
                        className={"inline-tag green"} key={enfant.id}>{enfant.nom}</i>)}
                    </div>
                )}

                {organisation_full.organisation.etat === "New" && organisation_full.organisation.prochainPrelevement !== null && (

                    <div className={"notice green"}>
                        Votre organisation est activée!
                        {organisation_full.organisation.balance > 0 ? (
                            <>
                                <br/>La balance de votre organisation est entière et sera prélevé ce soir à minuit pour le mois à venir.
                            </>
                        ) : (
                            <>
                                <br/>Vous êtes libre de faire les configurations que vous souhaitez
                                jusqu'au {afficherDate(organisation_full.organisation.prochainPrelevement)}.
                                <br/>Attention : Une fois vos configurations terminées, vous devez régler l'avance pour le mois
                                à venir avant la date butoir, sous peine de voir vos services désactivés.
                            </>

                        )}

                    </div>
                )}


                {organisation_full.organisation.desactivation_texte && (
                    <p className={"notice"}>{organisation_full.organisation.desactivation_texte}</p>
                )}

                <div className={"en-ligne en-tete-page"} style={{margin: "0", width: "100%"}}>


                    <i className={"inline-tag " + (organisation.balance < 0 ? "red" : (organisation.balance > ((organisation_full?.total && organisation_full?.total.toFixed(2)) ?? 0) ? "green" : "orange"))} style={{padding: "0.2rem 0.5rem"}}>Balance actuelle
                        : {organisation_full.organisation.balance.toFixed(2)}€</i>


                    <div></div>

                    <div className={"flex-row"}>

                        {user.roles.includes("ROLE_ADMIN") && (
                            <>
                                <Modale_recharger_balance organisation_full={organisation_full} refresh={() => {
                                    queryOrga();
                                }}/>
                            </>
                        )}

                        {/*<button onClick={() => {}}>*/}
                        {/*    <Icone nom={"eye"}/> Bouton 2*/}
                        {/*</button>*/}
                    </div>

                </div>



                {organisation_full.organisation.etat === "New" && organisation_full.organisation.prochainPrelevement === null && (
                    <div className={"notice grey"}>
                        Votre organisation n'est pas encore activée. Elle s'activera
                        automatiquement dès que vous configurerez une cellule.
                    </div>
                )}

                {organisation_full.organisation.etat === "Desac_paiement" && (
                    <div className={"notice red"}>
                        Votre organisation est bloquée.
                        {dernier_paiement && (
                            <>
                                <br/>Le dernier prélèvement de {dernier_paiement.montant}€
                                à eu lieu le {afficherDate(dernier_paiement.enregistrement)}.
                                <br/>Dans ce prélèvement est compris l'avance des cellules pour la période actuelle, qui sont par conséquent toujours activée mais les mesures vous sont cachés.
                                <br />Il vous suffit de recharger la balance de votre organisation pour que vos cellules soit débloqués.
                            </>
                        )}

                    </div>
                )}

                {/*J'ai rajouté" ça recemment sinon les orgas en New affichait le tableau vide avec Dernier paiement.
                Note du futur : Du coup ça merde, pour ça j'ai mis ||true */}
                {(organisation_full.organisation.etat !== "New" || true) && (
                    <>


                        {(!organisation_full.statuts && dernier_paiement) && (
                            <div className={"notice red"}>
                                Aucune cellule actuellement activée. Voici l'affichage du dernier paiement effectué :

                            </div>
                        )}

                        {(organisation_full.statuts || dernier_paiement) && (
                            <Tabs defaultValue="prochain">
                                <Tabs.List>
                                    <Tabs.Tab value="prochain" leftSection={<IconeList/>}>
                                        {organisation_full.statuts ? "Prochain prélevement" : "Dernier prélevement"}
                                    </Tabs.Tab>
                                    <Tabs.Tab value="historique" leftSection={<IconeListCheck/>}>
                                        Historique des prélèvement
                                    </Tabs.Tab>
                                </Tabs.List>

                                <Tabs.Panel value="prochain">




                                    {undefined !== organisation_full.total
                                    && undefined !== organisation_full.avance
                                    && undefined !== organisation_full.remboursement
                                    && undefined !== organisation_full.rattrapage ? (
                                        <>


                                            <div className={"zones"} style={{marginTop: "1rem"}}>
                                                Le {afficherDate(organisation_full.organisation.prochainPrelevement)},
                                                la somme de {organisation_full.total.toFixed(2)}€ sera prélévé dans la balance.
                                                Détails :
                                                <div className={"en-ligne"}>
                                                    <i className="inline-tag">Total avance
                                                        : {organisation_full.avance.toFixed(2)}€</i>
                                                    <i className="inline-tag">Total rattrapage
                                                        : {organisation_full.rattrapage.toFixed(2)}€</i>
                                                    <i className="inline-tag">Total remboursement
                                                        : {organisation_full.remboursement.toFixed(2)}€</i>
                                                </div>
                                                <div className={"tableauContainer"}>
                                                    <table>
                                                        <thead className={"hide_on_mobile"}>
                                                        <tr>
                                                            {organisation_full.enfants.length > 0 && (
                                                                <th>Organisation</th>
                                                            )}
                                                            <th>Cellule</th>
                                                            <th>Détails</th>
                                                            <th>Abonnement</th>
                                                            <th>Debut période actuelle</th>
                                                            <th>Fin période actuelle</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {organisation_full.statuts?.map(statut => (
                                                            statut.cellule.statut_actuel &&
                                                            <tr key={statut.cellule.statut_actuel?.id}>
                                                                {organisation_full.enfants.length > 0 && (
                                                                    <td>{statut.cellule.organisation.nom}</td>
                                                                )}
                                                                <td>
                                                                    <Link to={"/cellule/" + statut.cellule.id}
                                                                          className="btn sec"
                                                                          style={{justifyContent: "space-between"}}>
                                                                        <i className={"inline-tag " + (last_mesure_get_couleur(statut.cellule).color)}>
                                                                            {last_mesure_get_couleur(statut.cellule).texte}
                                                                        </i>
                                                                        {statut.cellule.nom}
                                                                        <i></i>
                                                                    </Link>
                                                                </td>
                                                                <td style={{textAlign: "left"}} className={"texte-petit"}>
                                                                    {statut.details.split("\n").map(ligne => <p
                                                                        className={"espace-entre-items"}>{ligne}</p>)}
                                                                </td>
                                                                <td>
                                                                    {statut.cellule.statut_actuel.statut.nom} ({statut.cellule.statut_actuel.statut.prix_achat}€/mois)
                                                                </td>
                                                                <td>
                                                                    {afficherDateTime(statut.cellule.statut_actuel.debut)}
                                                                </td>
                                                                <td>
                                                                    {afficherDateTime(statut.cellule.statut_actuel.fin)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>

                                                    </table>
                                                </div>

                                            </div>


                                        </>
                                    ) : (
                                        organisation_full?.paiements && dernier_paiement && (
                                            <div className={"zones"} style={{marginTop: "1rem"}}>

                                                <div className={"en-ligne"}>
                                                    <i className="inline-tag">Total avance
                                                        : {dernier_paiement.informations.avance && dernier_paiement.informations.avance.toFixed(2)}€</i>
                                                    <i className="inline-tag">Total rattrapage
                                                        : {dernier_paiement.informations.rattrapage && dernier_paiement.informations.rattrapage.toFixed(2)}€</i>
                                                    <i className="inline-tag">Total remboursement
                                                        : {dernier_paiement.informations.remboursement && dernier_paiement.informations.remboursement.toFixed(2)}€</i>
                                                </div>
                                                <div className={"tableauContainer"} style={{maxWidth: "unset"}}>
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th>Cellule</th>
                                                            <th>Détails</th>
                                                            <th>Abonnement</th>
                                                            <th>Debut période</th>
                                                            <th>Fin période</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {dernier_paiement.informations.statuts?.map(statut => (
                                                            statut.cellule.statut_actuel &&
                                                            <tr key={statut.cellule.statut_actuel.id}>
                                                                <td>
                                                                    <Link to={"/cellule/" + statut.cellule.id}
                                                                          className="btn sec">
                                                                        {statut.cellule.nom}
                                                                    </Link>
                                                                </td>
                                                                <td style={{textAlign: "left"}} className={"texte-petit"}>
                                                                    {statut.details.split("\n").map(ligne => <p
                                                                        className={"espace-entre-items"}>{ligne}</p>)}
                                                                </td>
                                                                <td>
                                                                    {statut.cellule.statut_actuel.statut.nom} ({statut.cellule.statut_actuel.statut.prix_achat}€/mois)
                                                                </td>
                                                                <td>
                                                                    {afficherDateTime(statut.cellule.statut_actuel.debut)}
                                                                </td>
                                                                <td>
                                                                    {afficherDateTime(statut.cellule.statut_actuel.fin)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>

                                                    </table>
                                                </div>

                                            </div>
                                        )
                                    )}
                                </Tabs.Panel>

                                <Tabs.Panel value="historique">
                                    <div className={"zones"} style={{marginTop: "1rem"}}>
                                        Détails des prélèvements déjà effectués sur l'organisation :
                                        <div className={"tableauContainer"}>
                                            {!organisation_full.paiements ? (
                                                <p className={"inline-tag"}>Aucun prélèvement déjà effectué.</p>
                                            ) : (
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Titre</th>
                                                        <th>Montant</th>
                                                        <th>Voir</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {organisation_full.paiements?.map(paiement => (
                                                        <tr key={paiement.id}>
                                                            <td>{afficherDateTime(paiement.enregistrement)}</td>
                                                            <td>{paiement.titre}</td>
                                                            <td>{paiement.montant}€</td>
                                                            <td>
                                                                <button
                                                                    className={"sec"}
                                                                    onClick={() => setModalePaiements(paiement.informations)}
                                                                ><IconeEye/></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>

                                                </table>
                                            )}

                                        </div>

                                    </div>
                                </Tabs.Panel>
                            </Tabs>
                        )}

                    </>
                )}




                <Modal className={"modale-fullscreen"} opened={modale_paiements !== null} onClose={() => {
                    setModalePaiements(null)
                }} title="Détails">
                    {modale_paiements && (
                        <div className={"zones"} style={{marginTop: "1rem"}}>
                            Le {afficherDate(modale_paiements.organisation.prochainPrelevement)},
                            la somme de {modale_paiements.total && modale_paiements.total.toFixed(2)}€ à été prélévé dans la
                            balance. Détails :
                            <div className={"en-ligne"}>
                                <i className="inline-tag">Total avance
                                    : {modale_paiements.avance && modale_paiements.avance.toFixed(2)}€</i>
                                <i className="inline-tag">Total rattrapage
                                    : {modale_paiements.rattrapage && modale_paiements.rattrapage.toFixed(2)}€</i>
                                <i className="inline-tag">Total remboursement
                                    : {modale_paiements.remboursement && modale_paiements.remboursement.toFixed(2)}€</i>
                                <i className="inline-tag">Balance de l'organisation après prélevement
                                    : {modale_paiements.organisation.balance.toFixed(2)}€</i>
                            </div>
                            <div className={"tableauContainer"} style={{maxWidth: "unset"}}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Cellule</th>
                                        <th>Détails</th>
                                        <th>Abonnement</th>
                                        <th>Debut période</th>
                                        <th>Fin période</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {modale_paiements.statuts?.map(statut => (
                                        statut.cellule.statut_actuel &&
                                        <tr key={statut.cellule.statut_actuel.id}>
                                            <td>
                                                <Link to={"/cellule/" + statut.cellule.id}
                                                      className="btn sec">
                                                    {statut.cellule.nom}
                                                </Link>
                                            </td>
                                            <td style={{textAlign: "left"}} className={"texte-petit"}>
                                                {statut.details.split("\n").map(ligne => <p
                                                    className={"espace-entre-items"}>{ligne}</p>)}
                                            </td>
                                            <td>
                                                {statut.cellule.statut_actuel.statut.nom} ({statut.cellule.statut_actuel.statut.prix_achat}€/mois)
                                            </td>
                                            <td>
                                                {afficherDateTime(statut.cellule.statut_actuel.debut)}
                                            </td>
                                            <td>
                                                {afficherDateTime(statut.cellule.statut_actuel.fin)}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}
                </Modal>

            </div>

        </>
    )
}