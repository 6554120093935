import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../services/AxiosInterceptor";
import {Avatar, Group, Modal, Select, Textarea, TextInput} from "@mantine/core";
import {Capteur} from "../../types/capteur";
import {Hub} from "../../types/hub";
import {Loading_div} from "../../components/loading_div/loading_div";
import {Link} from "react-router-dom";
import {IconeMicrochip} from "../../components/icone/solid_react/microchip";

export function Modale_voir_capteur_relations_hub({hub, setRelationHub}: { hub: Hub|null, setRelationHub:(hubs:Hub|null)=>void }) {
    const [opened, handlers] = useDisclosure(false);


    const [capteurs, setCapteurs] = useState<Capteur[]|null>(null);


    useEffect(() => {
        if (hub) {
            handlers.open()
            setCapteurs(null);
            ajax.get("/hubs/" + hub.uuid + "/capteurs").then(response => {
                setCapteurs(response.data.capteurs)
            }).catch(error => {})
        }
        else {
            handlers.close()
            setCapteurs(null);
        }
    }, [hub])


    return (
        <>
            <Modal opened={opened} onClose={() => {
                handlers.close()
                setRelationHub(null);
                setCapteurs(null);
            }} title={"Les enregistreurs utilisant actuellement le hub " + hub?.uuid}>
                <div className={"form"}>
                    {!capteurs ? (
                        <Loading_div />
                    ) : (
                        capteurs.length < 1 ? (
                            <p className={"inline-tag"}>Aucun enregistreur n'utilise ce hub.</p>
                        ) : (
                            capteurs.map(c => <Link className={"btn"} to={"/capteur/" + c.uuid}><IconeMicrochip/> {c.nom} ({c.uuid})</Link>)
                        )

                    )}
                </div>

            </Modal>
        </>

    )
}