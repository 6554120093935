import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../services/AxiosInterceptor";
import { IconeLoader} from "../../components/icone/icone";
import {Group, Menu, Modal, Select} from "@mantine/core";
import {Loading_button} from "../../components/loading_button/loading_button";
import {Capteur} from "../../types/capteur";
import {Sonde} from "../../types/sonde";
import {Extension} from "../../types/extension";
import {IconeOctagonPlus} from "../../components/icone/solid_react/octagon-plus";
import {notifications} from "@mantine/notifications";
import env_config from "../../env_config";
import {IconeCheck} from "../../components/icone/solid_react/check";
import {IconeArrowRight} from "../../components/icone/solid_react/arrow-right";
import {IconeFloppyDisk} from "../../components/icone/solid_react/floppy-disk";
import {IconeSquarePlus} from "../../components/icone/solid_react/square-plus";
import {IconePlus} from "../../components/icone/solid_react/plus";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Organisation} from "../../types/organisation";
import {Zone} from "../../types/zone";
import {useNavigate} from "react-router-dom";
import {editOrganisation} from "../../store/organisationSlice";
import {Modale_creer_zone} from "../../components/Modales/modale_creer_zone";

export function Modale_changer_sonde(
    {extension, capteur, setCapteur, dropdown_btns}
        :{extension:Extension, capteur:Capteur, setCapteur:(capteur:Capteur)=>void, dropdown_btns:boolean}) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    type Data = {image: string, label: string, value: string, description: string, group: string}[];
    const [data, setData] = useState<Data|null>(null);

    const [sondes, setSondes] = useState<Sonde[] | null>(null);
    const [value_sonde, setValue_sonde] = useState<Sonde | null>(null);

    const organisation = useSelector<RootState, Organisation>(state => state.organisation.list.filter(o => o.id === extension.organisation.id)[0])


    const [organisationModaleCreerZone, setOrganisationModaleCreerZone] = useState<Organisation | null>(null);

    const navigate = useNavigate();

    const dispatch = useDispatch();


    useEffect(() => {
        if (!opened) return;
        ajax.get("/extensions/" + extension.id + "/sondes").then(response => {

            let sondes:Sonde[] = response.data.sondes;
            let datamaj:Data = [];
            sondes.map(sonde => {
                datamaj.push({
                    image: '/logo192.png',
                    label: sonde.nom,
                    value: "" + sonde.id,
                    description: '',
                    group: sonde.affichage_nom_mesure,
                });
            })
            if (extension.sonde) setValue_sonde(sondes.filter(s => s.id === extension.sonde?.id)[0])
            setSondes(sondes);
            setData(datamaj);
        }).catch(error => {

        })
    }, [opened])

    function handleEnregistrer() {
        set_btn_loading(true);
        ajax.put("/extensions/" + extension.id, {
            "nom": extension.nom,
            "sonde_id": value_sonde?.id
        }).then(response => {
            let capteur_maj = { ...capteur };
            capteur_maj.extensions = capteur_maj.extensions?.map(_extension => {
                if (_extension.id === extension.id)
                {
                    _extension = response.data.extension;
                }
                return _extension;
            })
            setCapteur(capteur_maj);
            handlers.close();
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }


    function handleSupprimer() {
        if (extension.cellule) {
            if (!window.confirm("Cette extension est lié avec la cellule " + extension.cellule.nom + ", si vous supprimez la sonde cette cellule sera désactivée.\n" +
                "Si vous ajoutez une sonde en suivant, vous pourrez ré-activer la cellule avec la nouvelle sonde. \n" +
                "Etes-vous sûr de vouloir continuer ?"))
            {
                return;
            }
        }

        set_btn_loading(true);
        ajax.put("/extensions/" + extension.id, {
            "nom": extension.nom,
            "sonde_id": -1
        }).then(response => {
            let capteur_maj = { ...capteur };
            capteur_maj.extensions = capteur_maj.extensions?.map(_extension => {
                if (_extension.id === extension.id)
                {
                    _extension = response.data.extension;
                }
                return _extension;
            })
            setCapteur(capteur_maj);
            handlers.close();
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }

    interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
        image: string;
        label: string;
        description: string;
    }

    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ image, label, description, ...others }: ItemProps, ref) => (
            <div ref={ref} {...others}>
                <Group wrap={"nowrap"}>
                    {/*<Avatar src={image} />*/}

                    <div>
                        <p>{label}</p>
                        <p className={"description"}>{description}</p>
                    </div>
                </Group>
            </div>
        )
    );

    function creerCellule(extension: Extension, zone: Zone) {

        set_btn_loading(true);
        ajax.put("/extensions/" + extension.id, {
            "nom": extension.nom,
            "sonde_id": value_sonde?.id
        }).then(response => {
            let capteur_maj = { ...capteur };
            capteur_maj.extensions = capteur_maj.extensions?.map(_extension => {
                if (_extension.id === extension.id)
                {
                    _extension = response.data.extension;
                }
                return _extension;
            })
            setCapteur(capteur_maj);
            ajax.post("/zones/" + zone.id + "/cellules", {
                nom: capteur?.nom + " - " + extension.nom
            }).then(response => {
                handlers.close();
                set_btn_loading(false);
                navigate("/cellule/" + response.data.cellule.id + "?forcerconfig=true&capteur=" + extension.capteur.uuid + "&extension=" + extension.id)
            }).catch(error => {
            });
        }).catch(error => {
            set_btn_loading(false);
        })

    }




    return (
        <>
            {dropdown_btns ? (
                <>
                    {extension.sonde && (
                        <>
                            <button className={"sec"} onClick={handlers.open}><IconeOctagonPlus /> Changer la sonde</button>
                            <Loading_button className={"sec rouge"} onClick={handleSupprimer} icone={"toggle-off"} is_loading={btn_loading}>Désactiver l'extension</Loading_button>
                        </>
                    )}
                </>
            ) : (
                <div className={"en-ligne"} style={{justifyContent: "space-evenly", flex: "1"}}>
                    {!extension.sonde && (
                        <button className={"sec"} onClick={handlers.open}><IconeOctagonPlus /> Choisir la sonde</button>
                    )}
                </div>
            )}




            <Modal opened={opened} onClose={handlers.close} title="Indiquer l'appareil branché" zIndex={2000}>
                <div className={"form"}>
                        {data ? (
                            <>

                                <Select
                                    // itemComponent={SelectItem}
                                    label={"Quelle sonde est actuellement branchée ?"}
                                    data={Array.from(new Set(data.map(d => d.group))).map(group => {
                                        return {group: group, items: data.filter(d => d.group === group).map(d => {return {label: d.label, value: d.value}})}
                                    })  }
                                    maxDropdownHeight={400}
                                    value={"" + value_sonde?.id}
                                    onChange={v => setValue_sonde(sondes?.filter(s => "" + s.id === v)[0] ? sondes?.filter(s => "" + s.id === v)[0] : null)}
                                    className={"match_height"}
                                    comboboxProps={{ zIndex: 2002 }}
                                    renderOption={({ option }) => (
                                        <Group gap="sm">
                                            {/*<Avatar src={usersData[option.value].image} size={36} radius="xl" />*/}
                                            <div>
                                                <p className={"en-ligne"}>
                                                    {value_sonde?.id+"" === option.value ? (<IconeCheck />) : ""}
                                                    {data.find(e => e.value === option.value)?.label ?? ""}
                                                </p>
                                                <p className={"texte-info"}>
                                                    {data.find(e => e.value === option.value)?.description ?? ""}
                                                </p>
                                            </div>
                                        </Group>
                                    )}
                                />
                            </>

                        ) : (
                            <div className={"en-ligne"}><IconeLoader /> Chargement...</div>
                            )}


                    {value_sonde?.type_de_donnee === "bool" && (
                        <div style={{fontSize: "var(--texte-size-petit)"}}>
                            <div className={"en-ligne"}>
                                Pour la sonde
                                <p className={"inline-tag"}>{value_sonde.nom}</p>
                                l'affichage sera :
                            </div>
                            <p><i>"{value_sonde.affichage_vrai}"</i> quand le circuit electrique du contact sec est fermé</p>
                            <p><i>"{value_sonde.affichage_faux}"</i> quand le circuit electrique du contact sec est ouvert</p>
                        </div>

                    )}

                    {extension.sonde && (
                        <p className="inline-tag">Les sondes qui vous sont proposés sont celle compatibles avec celle déjà indiqué.
                            Pour choisir un autre type de sonde, vous devez d'abord désactiver l'extension.</p>
                    )}

                    <div className={!extension.cellule ? "en-ligne spacebetween" : ""}>
                        <Loading_button is_loading={btn_loading} disabled={!value_sonde}
                                        onClick={handleEnregistrer} className={"sec"}><IconeFloppyDisk /> Enregistrer</Loading_button>

                        {!extension.cellule && (
                            <Menu shadow="md" width={200} zIndex={2001}>
                                <Menu.Target>
                                    <button className={""} disabled={!value_sonde}>
                                        {btn_loading ? (
                                            <>
                                                <IconeLoader /> Chargement...
                                            </>
                                        ) : (
                                            <>
                                                <IconeCheck /> Continuer en créeant une cellule <IconeArrowRight />
                                            </>
                                        )}
                                    </button>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>Dans la zone :</Menu.Label>
                                    {organisation.zones?.map(zone => {
                                        if (zone.nom === "base.config.appareils") return <></>
                                        return (
                                            <Menu.Item onClick={() => creerCellule(extension, zone)}>
                                                {zone.nom}
                                            </Menu.Item>
                                        )
                                    })}
                                    <Menu.Item
                                        onClick={() => {
                                            setOrganisationModaleCreerZone(organisation)
                                        }}
                                        className={"en-ligne"}
                                        leftSection={<IconePlus/>}
                                    >
                                        Créer une zone
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        )}

                    </div>


                </div>

            </Modal>

            <Modale_creer_zone organisation={organisationModaleCreerZone} setOrganisation={orga => {
                setOrganisationModaleCreerZone(null);
                if (orga) dispatch(editOrganisation(orga))
            }}></Modale_creer_zone>
        </>

    )
}